/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

// import { getContactsAppointments } from "../../../api/AppointmentsApi";

import BigCombo from '../../../components/molecules/BigCombo/BigCombo';
import DropStepZone from '../../../components/molecules/DropStepZone/DropStepZone';
import { Modal } from '../../../components/atoms/Modal/Modal';
import { NewTag } from '../../../components/atoms/NewTag/NewTag';
import RadioField from '../../../components/molecules/RadioField/RadioField';
import { SorterButton } from '../../../components/atoms/SorterButton/SorterButton';
import Icon from '../../../components/atoms/Icon/Icon';
import {
  getAllConsultants,
  getConsultantInfos,
  getTalentsByConsultant,
} from '../../../api/ConsultantApi';
import { getUser } from '../../../api/userAPI';

import './AddTalentsPopin.scss';
import InputField from '../../../components/molecules/InputField/InputField';
import PrimaryButton from '../../../components/atoms/PrimaryButton/PrimaryButton';
// import { getTalentsGroup } from "../../../api/groupsApi";

export const AddTalentsPopin = ({ open, onClose, user, setMembers, talentsList, currentList, subRole, mission, }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [newTalent, setNewTalent] = useState({
    comments: '',
    email: '',
    first_name: '',
    last_name: '',
    gender: '',
    image_url: '',
    consultant_id: user.profile_id,
    consultant_userid: user.id,

  });
  const [talents, setTalents] = useState(talents || []);
  const [consultants, setConsultants] = useState([]);

  const [newMembers, setNewMembers] = useState([]);
  const [disabledButtonCreate, setDisabledButtonCreate] = useState(true);
  const [isImport, setIsImport] = useState(false);
  const [isIndiv, setIsIndiv] = useState(true);
  const [disabledAddNewTalent, setDisabledAddNewTalent] = useState(true);
  const [titleActionButton, setTitleActionButton] = useState('');
  const [isFile, setIsFile] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState(user);

  // ------------------------     Use Effects     ------------------------ //

  /**
   * Get all Talents
   */
  useEffect(() => {
    const array = [];
    const idSet = new Set(currentList?.map((obj) => obj.id));
    const filteredList1 = talentsList?.filter((obj) => !idSet.has(obj.id));
    if (filteredList1) {
      filteredList1.forEach((user) => {
        const talents = { label: `${user.first_name} ${user.last_name}`, value: user };
        array.push(talents);
      });
      setTalents(array.sort((a, b) => (a.first_name < b.first_name ? 1 : -1)));
    }
    // }
  }, []);

  /**
   * Get all consultants
   */
  useEffect(() => {
    if (user && user.office_id) {
      const array = [];
      getAllConsultants(user.office_id).then((response) => {
        if (!response.consultants) return;
        response.consultants.forEach((u) => {
          const consultant = { label: `${u.user.first_name} ${u.user.last_name}`, value: u };
          array.push(consultant);
        });
        setConsultants(array);
      });
    }
  }, []);

  // ------------------------     Talents     ------------------------ //

  /**
   * Check if all fields are filled
   */
  useEffect(() => {
    const { gender, first_name, last_name, email } = newTalent;

    if (!gender || !first_name || !last_name || !email) {
      setDisabledAddNewTalent(true);
    } else {
      setDisabledAddNewTalent(false);
    }
  }, [newTalent]);

  /**
   * Check if newMembers
   */
  useEffect(() => {
    newMembers && newMembers.length > 0 && setDisabledButtonCreate(false);
  }, [newMembers]);

  /**
   *  Get Talents by Consultant
   * @param {*} id
   */
  const getTalentsConsultant = async (id) => {
    try {
        const data = await getTalentsByConsultant(id);
        if (data.beneficiaries) {
            const array = [];
            const idSet = new Set(currentList?.map((obj) => obj.id));
            const filteredList1 = data.beneficiaries.filter((obj) => !idSet.has(obj.user_id));
            for (const u of filteredList1) {
                const rep = await getUser(u.user_id);
                const talents = {
                    label: `${rep.user.first_name} ${rep.user.last_name}`,
                    value: rep.user,
                };
                array.push(talents);
            }

            setTalents(array.sort((a, b) => (a.first_name > b.first_name ? 1 : -1)));
        }
    } catch (error) {
        console.error("Une erreur s'est produite :", error);
    }
  };

  /**
   *  Check if member has already been added
   * @param {*} member
   * @returns
   */
  const checkedMemberHasAlreadyBeenAdded = (member) => {
    return newMembers.includes(member);
  };

  /**
   *  Delete member
   * @param {*} member
   */
  const handleDeleteMemberClick = (member) => {
    const filterMembers = newMembers.filter((m) => m !== member);
    setNewMembers(filterMembers);
  };

  // ------------------------     Handlers     ------------------------ //

  /**
   *  Add new member
   */
  const handleAddMember = () => {
    setMembers(newMembers);
    setIsOpen(false);
  };

  /**
   *  Handle Input On Change
   * @param {*} input
   */
  const handleInputChange = (input) => {
    const { id, value } = input;
    setNewTalent({ ...newTalent, [id]: value });
  };

  /**
   *  Handle Radio On Change
   * @param {*} radio
   */
  const handelRadioChange = (radio) => {
    const { value } = radio;
    setNewTalent({ ...newTalent, gender: value });
  };

  /**
   *  Handle Exisiting Talents Dropdown Selected
   * @param {*} dropdown
   */
  const handleExistingTalentsDropdown = (dropdown) => {
    const { value } = dropdown;
    const talent = value.value;
    // talent.consultant_id = selectedConsultant.id;
    talent.consultant_userid = selectedConsultant.user_id ?? selectedConsultant.id;
    if (!checkedMemberHasAlreadyBeenAdded(talent)) {
      setNewMembers([...newMembers, talent]);
    }
    
  };

  /**
   *  Handle Consultant Dropdown
   * @param {*} dropdown
   */
  const handleConsultantDropdown = (dropdown) => {
    const { value } = dropdown;
    const consultant = value.value;
    setSelectedConsultant(consultant);
    getTalentsConsultant(consultant.id);
    setNewTalent({ ...newTalent, consultant_id: consultant.id, consultant_userid: consultant.user_id });
  };

  /**
   *  Handle New Talents //TODO: Impossible de créer un Talent car il faut le relier à l'api et aussi lui définir automatiquement un consultant (via API)
   */
  const handleNewTalentClick = () => {
    if (!checkedMemberHasAlreadyBeenAdded(newTalent)) {
      setNewMembers([...newMembers, newTalent]);
    }
    setNewTalent({
      comments: '',
      email: '',
      first_name: '',
      last_name: '',
      gender: '',
      image_url: '',
      consultant_id: user.profile_id,
      consultant_userid: user.id,
    });
  };

  /**
   *  Handle On Drop File
   * @param {*} file
   */
  const handleOnDropFile = (file) => {
    file && setIsFile(true);
  };

  /**
   *  Handle Delete File
   */
  const handleDeleteFile = () => {
    setIsFile(false);
  };

  // ------------------------     Utils     ------------------------ //

  /**
   *  Get Value Sorter
   * @param {*} filter
   */
  const getValueSorter = (filter) => {
    const { value } = filter;
    value === 'indiv' ? setTitleActionButton('Ajouter') : setTitleActionButton('Ajouter');
  };

  // ------------------------     Import files     ------------------------ //

  /**
   *  Handle File Import
   * @param {*} file
   */
  useEffect(() => {
    isFile && setDisabledButtonCreate(false);
  }, [isFile]);
  // use effects
  // useEffect(() => {
  //     // getTalentsGroup(user.id, group_id).then((response) => {
  //     //     const array = [];
  //     //     response.map(user => {
  //     //         const talents = { label: `${user.first_name} ${user.last_name}`, value: user }
  //     //         array.push(talents)
  //     //     })
  //     //     setTalents(array)
  //     // })
  // }, [group_id, user.id])

  // ------------------------     Renders     ------------------------ //

  /**
   *  Render Filters
   * @returns
   */
  const renderFilters = () => {
    return (
      <div className="popin-add-group-filters">
        <SorterButton
          label={'Ajouter existant'}
          value={'indiv'}
          sendValue={getValueSorter}
          active={isIndiv}
          setActive={() => {
            setIsIndiv(true);
            setIsImport(false);
          }}
        />
         <SorterButton
          label={'Ajouter nouveau'}
          value={'new'}
          sendValue={getValueSorter}
          active={isImport}
          setActive={() => {
            setIsImport(true);
            setIsIndiv(false);
          }}
        />
        
      </div>
    );
  };

  /**
   *  Render New Members
   * @returns
   */
  const renderNewMembers = () => {
    const countNewMembers = newMembers.length;
    return (
      <div className="new-members">
        <label>Nouveaux membres à ajouter ({countNewMembers}) </label>
        <div className="new-members-container">
          {newMembers.map((member) => (
            <NewTag
              title={`${member.first_name} ${member.last_name}`}
              icon={'close'}
              onClickIcon={() => handleDeleteMemberClick(member)}
            />
          ))}
        </div>
      </div>
    );
  };

  /**
   *  Render Add Member
   * @returns
   */
  const renderAddMember = () => {
    const { gender, first_name, last_name, email } = newTalent;
    const genders = [
      { id: 'male', value: 'male', label: 'M' },
      { id: 'female', value: 'female', label: 'Mme' },
    ];
    let tmpTalent = [...talents]
    tmpTalent = tmpTalent.filter(c => (c.label && c.value && c.value.id && c.value.first_name && c.value.last_name && c.value.email)).sort((a, b) => a.value.first_name.localeCompare(b.value.first_name))
    let tmpConsultant = consultants.filter(c => (c.label && c.value && c.value.user_id && c.value.user.first_name && c.value.user.last_name && c.value.user.email)).sort((a, b) => a.value.user.first_name.localeCompare(b.value.user.first_name))
    tmpTalent.unshift({ label: 'Aucun', value: {first_name: 'consultant :', last_name: selectedConsultant?.user ? selectedConsultant?.user?.first_name : selectedConsultant?.first_name} })
    return (
      <div className="add-new-members">
        <div className="choose-existing-talent">
          <label>Choisir un consultant</label>
          <BigCombo
            title={''}
            options={tmpConsultant ? tmpConsultant : []}
            value={user.first_name + ' ' + user.last_name}
            onChange={handleConsultantDropdown}
            disabled={subRole !== "consultant_admin"}
          />
        </div>
        <div className="choose-existing-talent">
          <label>Ajouter un talent existant</label>
          <BigCombo
            title={''}
            options={tmpTalent ? tmpTalent : []}
            value={'Les talents'}
            onChange={handleExistingTalentsDropdown}
          />
        </div>

        {newMembers && newMembers.length > 0 && renderNewMembers()}
      </div>
    );
  };

  /**
   *  Render Import Members
   * @returns
   */
  const renderImportMembers = () => {
    const { gender, first_name, last_name, email } = newTalent;
    const genders = [
      { id: 'male', value: 'male', label: 'M' },
      { id: 'female', value: 'female', label: 'Mme' },
    ];
    return (
      <>
        <div className="new-members" >
          <div className="add-new-talent" style={{width: '100%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <label>Choisir un consultant</label>
            <BigCombo
            title={''}
            options={consultants ? consultants : []}
            value={user.first_name + ' ' + user.last_name}
            onChange={handleConsultantDropdown}
            disabled={subRole !== "consultant_admin"}
          />
            <div>
            <label>Nouveau talent</label>
            <RadioField
              title={''}
              name={'civility'}
              inputList={genders}
              value={gender}
              onChange={handelRadioChange}
            />
            <InputField
              title={'Adresse email'}
              value={email}
              name={'email'}
              required
              onChange={handleInputChange}
            />
            <div className="add-new-talent-info">
              <InputField
                title={'Prénom'}
                value={first_name}
                name={'first_name'}
                required
                onChange={handleInputChange}
              />
              <InputField
                title={'Nom'}
                value={last_name}
                name={'last_name'}
                required
                onChange={handleInputChange}
              />
            </div>
            </div>
            <div
              className="add-new-talent-action"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <PrimaryButton
                label={'Ajouter à la liste'}
                onClick={handleNewTalentClick}
                disabled={disabledAddNewTalent}
              />
            </div>
          </div>
          {newMembers && newMembers.length > 0 && renderNewMembers()}
        </div>
      </>
    );
  };

  /**
   * Render Content
   * @returns
   */
  const renderContent = () => {
    return (
      <div>
        {renderFilters()}
        <div className="add-group-popin-content">
          {isImport ? renderImportMembers() : renderAddMember()}
        </div>
      </div>
    );
  };
  return (
    <Modal
      onClosePopin={onClose}
      openPopin={isOpen}
      title={'Ajouter des membres'}
      content={renderContent()}
      cancelButton={'Annuler'}
      actionButton={titleActionButton}
      handleActionButton={handleAddMember}
      disabledActionButton={disabledButtonCreate}
    />
  );
};
