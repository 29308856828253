import React from 'react';
import Icon from '../../atoms/Icon/Icon';
import './DataTable.scss';
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton';
import CircleButton from '../../atoms/CircleButton/CircleButton';
import icon from '../../atoms/Icon/Icon';
import { updateTest } from '../../../api/CentralTestApi';

const DataTable = ({ columns = [], data = null, postResendTest = null, addToaster = null, isConsultant = false }) => {

  const handleChangeShowReport = async (id, show_report) => {
    console.log('updateTest',data.find((test) => test.id === id));
      const new_test = data.find((test) => test.id === id);
    if (show_report) {
      new_test.show_report = false;
      try {
        await updateTest(new_test);
        addToaster('Le rapport du test est maintenant visible par le talent', "Un e-mail a été envoyé au talent.", 'success');
      } catch (error) {
        addToaster('Erreur lors de la mise à jour du test', error.message, 'error');
      }
      addToaster('Le rapport du test n\'est plus visible par le talent', "Un e-mail a été envoyé au talent.", 'success');

    } else {
      
      new_test.show_report = true;
      try {
        await updateTest(new_test);
        addToaster('Le rapport du test est maintenant visible par le talent', "Un e-mail a été envoyé au talent.", 'success');
      } catch (error) {
        addToaster('Erreur lors de la mise à jour du test', error.message, 'error');
      }
    }

  }

  return (
    <table className="tests-list">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {(data && data.length > 0) ? (
          data.map((row, index) => (
            <tr key={index}>
              <td className="test-name"><Icon icon={icon.icon.Question}/>{row.test_title}</td>
              <td>{row.test_status}</td>
              <td>{row.assessment_start_date}</td>
              <td>{row.assessment_end_date}</td>
              {columns.includes('Lien du test') && (
                <td>
                  <CircleButton icon={Icon.icon.Preview} onClick={() => window.open(row.assessment_link)} />
                </td>
              )}
             {(columns.includes('Rapport talent')) && (
              <td>
              {(row.company_report_pdf_link !== null && (row.show_report || isConsultant)) ? (<a
                style={{ background: 'var(--main-color)', color: 'white', display: 'flex', gap: '5px', padding: '2px 1px', border: '1px solid var(--main-color)', borderRadius: '5px', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
                href={row.candidate_report_pdf_link}
                target="_blank"
              >
                <Icon icon={icon.icon.Preview} color={'white'} />
                Consulter
              </a>) : "Non disponible"}
              
            </td>
             )}
              {columns.includes('Actions') && <td>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <CircleButton color={row.show_report ? icon.color.White : icon.color.Accent} backgroundColor={!row.show_report ? icon.color.White : Icon.color.Accent}  icon={Icon.icon.Preview} onClick={() => handleChangeShowReport(row.id, row.show_report)} />
                    {/* <CircleButton disabled={!(row.candidate_report_pdf_link !== null)} icon={Icon.icon.File} onClick={() => window.open(row.candidate_report_pdf_link)} />
                    <CircleButton icon={Icon.icon.Mail} onClick={() => postResendTest(row.id)} /> */}
                </div>
              </td>}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="6">Aucune donnée.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default DataTable;
