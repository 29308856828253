import React from "react"
import { Modal } from "../../../components/atoms/Modal/Modal";
// import { BackgroundIcon } from "../../../components/atoms/BackgroundIcon/BackgroundIcon";
import moment from 'moment';
import 'moment/locale/fr';

import './DeleteAppointmentPopin.scss'

export const DeleteAppointmentPopin = ({ open, onClose, appointment, handleDeleteAppointment }) => {

    const renderContent = () => {
        if (appointment) {
            return (
                <div className="delete-appointment-popin-content">
                    <p>Êtes-vous sûr(e) de vouloir supprimer définitivement le rendez-vous du <span> {moment(appointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')} à {moment(appointment.rdv_at, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')} </span> ?</p>
                    <p>Les invités de ce rendez-vous seront informés de sa suppression.</p>
                </div>
            )
        }
    }

    return (
        <Modal
            onClosePopin={onClose}
            openPopin={open}
            title={"Suppression du rendez-vous"}
            content={renderContent()}
            cancelButton={"Retour"}
            actionButton={"Supprimer le rendez-vous"}
            handleActionButton={handleDeleteAppointment}
        />
    )
}