/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { selectors as NavigationSelectors } from '../../redux/NavigationRedux';
import {
  actions as BeneficiaryActions,
  selectors as BeneficiarySelectors,
} from '../../redux/BeneficiaryRedux';
import { selectors as UserSelectors } from '../../redux/UserRedux';

import Page from '../../containers/global/Page/Page';
import ConsultantBeneficiary from '../../containers/consultant/ConsultantBeneficiary/ConsultantBeneficiary';
import ConsultantBeneficiaryAside from '../../containers/consultant/ConsultantBeneficiaryAside/ConsultantBeneficiaryAside';
import localFinancer from '../../components/organisms/FinancialCard/FinancialCard.states';
import AccompEditionCard from '../../components/organisms/AccompEditionCard/AccompEditionCard';
import { reduxForm } from 'redux-form';
// import moment from "moment";
import { useLocation } from 'react-router-dom';
import {
  AccompanimentTypeLocale,
  accompanimentTypes,
  accompanimentDurationTypes,
  appraisalTypes,
  appraisalAvailabilities,
  vaeAvailabilities,
  termsList,
  AccompanimentCoJobLocale,
  AccompanimentCoSystemLocale,
  optionsYesNo,
  AccompanimentCoFollowupProjectLocale,
  optionsSitutation,
  optionsNumbers,
  AccompCoFormationLocale,
  optionsTypeFormation,
  optionsOpinionConsultant,
  optionsFinancing,
  AccompanimentCoSolutionLocale,
  optionsSolutions,
  optionsDifferentialRemuneration,
  activityOptions,
  typeCollectiveAccompaniment,
  AccompanimentSyntheseCoLocale,
  situationFollowUpList,
  formationList,
  financerStateList,
  financerTypeList,
  financerOpacifTypeList,
  financialTVAList,
  financerTypeLocale
} from './AccompanimentFields/index';

import { asyncValidate } from '../../helpers/form/FormValidator/FormValidator';
import CurrentJobCard from '../../components/organisms/CurrentJobCard/CurrentJobCard';
import SolutionCard from '../../components/organisms/SolutionCard/SolutionCard';
import FollowupProjectCard from '../../components/organisms/FollowupProjectCard/FollowupProjectCard';
import FormationCard from '../../components/organisms/FormationCard/FormationCard';
import { AccompCoSynthesisCard } from '../../components/organisms/AccompCoSynthesisCard/AccompCoSynthesisCard';
import AccompCoSystemCard from '../../components/organisms/AccompCoSystemCard/AccompCoSystemCard';
import SyntheseCoCard from '../../components/organisms/SyntheseCoCard/SyntheseCoCard';

import './ConsultantBeneficiaryAccompanimentPage.scss';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import { getUserProfile, updateUserProfile } from '../../api/ProfileApi';
import moment from 'moment';
import { AccompClientMissionCard } from '../../components/organisms/AccompClientMissionCard/AccompClientMissionCard';
import { DocumentAccCard } from '../../components/organisms/DocumentsAccCard/DocumentsAccCard';
import FinancialCard from '../../components/organisms/FinancialCard/FinancialCard';
import FinancialEditionCard from '../../components/organisms/FinancialEditionCard/FinancialEditionCard';

const mapStateToProps = (state, props) => ({
  id: props.match.params.user_id,
  pathname: NavigationSelectors.pathname(state),
  beneficiary: BeneficiarySelectors.beneficiary(state),
  user: UserSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => ({
  getBeneficiary: (id) => dispatch(BeneficiaryActions.getBeneficiary(id)),
});

function ConsultantBeneficiaryAccompanimentPage(props) {
  const [isTalent, setIsTalent] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(true);
  const [accompanimentType, setAccompanimentType] = useState('appraisal');

  const [userAppraisalAccompaniment, setUserAppraisalAccompaniment] = useState({});
  const [userVAEAccompaniment, setUserVAEAccompaniment] = useState({});
  const [userCollectiveAccompaniment, setUserCollectiveAccompaniment] = useState({});
  const [userOutplacementIndividualAccompaniment, setOutplacementIndividualAccompaniment] =
    useState({});
  const [userCoachingAccompaniment, setUserCoachingAccompaniment] = useState({});
  const [userFormationAccompaniment, setUserFormationAccompaniment] = useState({});
  const [userBusinessCreationAccompaniment, setUserBusinessCreationAccompaniment] = useState({});
  const [userCollectiveSchemeAccompaniment, setUserCollectifSchemeAccompaniment] = useState({});
  const [userOrientationReview, setUserOrientationReview] = useState({});
  const [userOtherAccompaniment, setUserOtherAccompaniment] = useState({});
  const [selected_accompaniment, setSelectedAccompaniment] = useState({});
  const [financial, setFinancial] = useState({});
  
  const [financialDocuments, setFinancialDocuments] = useState([]);

  const [documents, setDocuments] = useState([]);
  const location = useLocation();

  useEffect(() => {
    getUserProfile(props.id).then((response) => {
      const res = response.profile;
      const activeAccompaniment = accompanimentTypes.find(
        (element) => element.object === res.selected_accompaniment,
      );
      setSelectedAccompaniment(res.selected_accompaniment);
      setDocuments(response.profile.documents);
      if (res.financial.url){
        setFinancialDocuments([{
          url: res.financial.url,
          title: res.financial.doc_title,
        }]);
      }
      activeAccompaniment && setAccompanimentType(activeAccompaniment.value);

      if (res) {
        const userAppraisalAccompaniment = {
          ...res.user_appraisal_accompaniment,
          accompaniment_type: 'appraisal',
          accompaniment_appraisal_type:
            res.user_appraisal_accompaniment?.accompaniment_appraisal_type || 'pole_emploi',
          accompaniment_appraisal_availability:
            res.user_appraisal_accompaniment?.accompaniment_appraisal_availability || 'within_work',
          accompaniment_terms:
            res.user_appraisal_accompaniment?.accompaniment_terms || 'face_to_face',
          accompaniment_start_date: formatedDate(
            res.user_appraisal_accompaniment?.accompaniment_start_date,
          ),
          accompaniment_end_date: formatedDate(
            res.user_appraisal_accompaniment?.accompaniment_end_date,
          ),
          accompaniment_date_six_months: formatedDate(
            res.user_appraisal_accompaniment?.accompaniment_date_six_months,
          ),
          accompaniment_first_appointment_date: formatedDate(
            res.user_appraisal_accompaniment?.accompaniment_first_appointment_date,
          )
          
        };

        // OK
        setUserAppraisalAccompaniment(userAppraisalAccompaniment);

        // OK
        setOutplacementIndividualAccompaniment({
          ...res.outplacement_individual_accompaniment,
          accompaniment_type: 'outplacement_individual',
          accompaniment_start_date: formatedDate(
            res.outplacement_individual_accompaniment?.accompaniment_start_date,
          ),
          accompaniment_end_date: formatedDate(
            res.outplacement_individual_accompaniment?.accompaniment_end_date,
          ),
          accompaniment_outplacement_individual_join_date: formatedDate(
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_join_date,
          ),
          accompaniment_outplacement_individual_start_date: formatedDate(
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_start_date,
          ),
          accompaniment_outplacement_individual_end_date: formatedDate(
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_end_date,
          ),
          accompaniment_outplacement_individual_start_job_date: formatedDate(
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_start_job_date,
          ),
          accompaniment_outplacement_individual_formation_start_date: formatedDate(
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_formation_start_date,
          ),
          accompaniment_outplacement_individual_formation_end_date: formatedDate(
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_formation_end_date,
          ),
          accompaniment_outplacement_individual_real_end_date: formatedDate(
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_real_end_date,
          ),

          accompaniment_outplacement_individual_signed_eeo:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_signed_eeo || 'no',
          accompaniment_outplacement_individual_signed_membership_charter:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_signed_membership_charter || 'no',

          accompaniment_outplacement_individual_solutions:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_solutions || 'other',
          accompaniment_outplacement_individual_compensation_remuneration:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_compensation_remuneration || 'no',
          accompaniment_outplacement_individual_new_activity_sector:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_new_activity_sector || 'other',

          accompaniment_outplacement_individual_situations:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_situations || 'reception',
          accompaniment_outplacement_individual_number_rdv:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_number_rdv || 0,
          accompaniment_outplacement_individual_number_job_offers:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_number_job_offers || 0,
          accompaniment_outplacement_individual_number_workshop:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_number_workshop || 0,

          accompaniment_outplacement_individual_formation_type:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_formation_type || 'other',
          accompaniment_outplacement_individual_opinion_consultant:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_opinion_consultant || 'no-opinion',
          accompaniment_outplacement_individual_financing_formation:
            res.outplacement_individual_accompaniment
              ?.accompaniment_outplacement_individual_financing_formation || 'none',
          synthesis: res.outplacement_individual_accompaniment?.synthesis || '',
        });

        // OK
        setUserVAEAccompaniment({
          ...res.user_VAE_accompaniment,
          accompaniment_type: 'vae',
          accompaniment_start_date: formatedDate(
            res.user_VAE_accompaniment?.accompaniment_start_date,
          ),
          accompaniment_end_date: formatedDate(res.user_VAE_accompaniment?.accompaniment_end_date),
          accompaniment_eligibility_date: formatedDate(
            res.user_VAE_accompaniment?.accompaniment_eligibility_date,
          ),
          accompaniment_first_appointment_date: formatedDate(
            res.user_VAE_accompaniment?.accompaniment_first_appointment_date,
          )
        });

        // OK
        setUserCoachingAccompaniment({
          ...res.coaching_accompaniment,
          accompaniment_type: 'coaching',
          accompaniment_start_date: formatedDate(
            res.coaching_accompaniment?.accompaniment_start_date,
          ),
          accompaniment_first_appointment_date: formatedDate(
            res.coaching_accompaniment?.accompaniment_first_appointment_date,
          ),
          accompaniment_end_date: formatedDate(res.coaching_accompaniment?.accompaniment_end_date),
        });

        // OK
        setUserCollectiveAccompaniment({
          ...res.user_collective_accompaniment,
          accompaniment_type: 'collective_accompaniment',
          accompaniment_co_join_date: formatedDate(
            res.user_collective_accompaniment?.accompaniment_co_join_date,
          ),
          accompaniment_co_start_date: formatedDate(
            res.user_collective_accompaniment?.accompaniment_co_start_date,
          ),
          accompaniment_co_end_date: formatedDate(
            res.user_collective_accompaniment?.accompaniment_co_end_date,
          ),
          accompaniment_co_start_job_date: formatedDate(
            res.user_collective_accompaniment?.accompaniment_co_start_job_date,
          ),
          accompaniment_co_formation_start_date: formatedDate(
            res.user_collective_accompaniment?.accompaniment_co_formation_start_date,
          ),
          accompaniment_co_formation_end_date: formatedDate(
            res.user_collective_accompaniment?.accompaniment_co_formation_end_date,
          ),
          accompaniment_co_real_end_date: formatedDate(
            res.user_collective_accompaniment?.accompaniment_co_real_end_date,
          ), // ICI

          accompaniment_collective_type:
            res.user_collective_accompaniment?.accompaniment_collective_type ||
            'reclassification_leave',

          accompaniment_co_signed_eeo:
            res.user_collective_accompaniment?.accompaniment_co_signed_eeo || 'no',
          accompaniment_co_signed_membership_charter:
            res.user_collective_accompaniment?.accompaniment_co_signed_membership_charter || 'no',

          accompaniment_co_solutions:
            res.user_collective_accompaniment?.accompaniment_co_solutions || 'other',
          accompaniment_co_compensation_remuneration:
            res.user_collective_accompaniment?.accompaniment_co_compensation_remuneration || 'no',
          accompaniment_co_new_activity_sector:
            res.user_collective_accompaniment?.accompaniment_co_new_activity_sector || 'other',

          accompaniment_co_situations:
            res.user_collective_accompaniment?.accompaniment_co_situations || 'reception',
          accompaniment_co_number_rdv:
            res.user_collective_accompaniment?.accompaniment_co_number_rdv || 0,
          accompaniment_co_number_job_offers:
            res.user_collective_accompaniment?.accompaniment_co_number_job_offers || 0,
          accompaniment_co_number_workshop:
            res.user_collective_accompaniment?.accompaniment_co_number_workshop || 0,

          accompaniment_co_formation_type:
            res.user_collective_accompaniment?.accompaniment_co_formation_type || 'other',
          accompaniment_co_opinion_consultant:
            res.user_collective_accompaniment?.accompaniment_co_opinion_consultant || 'no-opinion',
          accompaniment_co_financing_formation:
            res.user_collective_accompaniment?.accompaniment_co_financing_formation || 'none',
          synthesis: res.user_collective_accompaniment?.synthesis || '',
        });

        // OK
        setUserOrientationReview({
          ...res.user_orientation_review,
          accompaniment_type: 'orientation_review',
          accompaniment_start_date: formatedDate(
            res.user_orientation_review?.accompaniment_start_date,
          ),
          accompaniment_end_date: formatedDate(res.user_orientation_review?.accompaniment_end_date),
          accompaniment_first_appointment_date: formatedDate(
            res.user_orientation_review?.accompaniment_first_appointment_date,
          )
        });

        // OK
        setUserBusinessCreationAccompaniment({
          ...res.business_creation_accompaniment,
          accompaniment_type: 'business_creation',
          accompaniment_start_date: formatedDate(
            res.business_creation_accompaniment?.accompaniment_start_date,
          ),
          accompaniment_end_date: formatedDate(
            res.business_creation_accompaniment?.accompaniment_end_date,
          ),
          accompaniment_first_appointment_date: formatedDate(
            res.business_creation_accompaniment?.accompaniment_first_appointment_date,
          )
        });

        // OK
        setUserCollectifSchemeAccompaniment({
          ...res.collectif_scheme_accompaniment,
          accompaniment_type: 'collective_scheme',
          accompaniment_start_date: formatedDate(
            res.collectif_scheme_accompaniment?.accompaniment_start_date,
          ),
          accompaniment_end_date: formatedDate(
            res.collectif_scheme_accompaniment?.accompaniment_end_date,
          ),
          accompaniment_collectif_scheme_join_date: formatedDate(
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_join_date,
          ),
          accompaniment_collectif_scheme_start_date: formatedDate(
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_start_date,
          ),
          accompaniment_collectif_scheme_end_date: formatedDate(
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_end_date,
          ),
          accompaniment_collectif_scheme_start_job_date: formatedDate(
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_start_job_date,
          ),
          accompaniment_collectif_scheme_formation_start_date: formatedDate(
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_formation_start_date,
          ),
          accompaniment_collectif_scheme_formation_end_date: formatedDate(
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_formation_end_date,
          ),
          accompaniment_collectif_scheme_real_end_date: formatedDate(
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_real_end_date, //ICI
          ),
          accompaniment_collectif_scheme_signed_eeo:
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_signed_eeo || 'no',
          accompaniment_collectif_scheme_signed_membership_charter:
            res.collectif_scheme_accompaniment
              ?.accompaniment_collectif_scheme_signed_membership_charter || 'no',

          accompaniment_collectif_scheme_solutions:
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_solutions || 'other',
          accompaniment_collectif_scheme_compensation_remuneration:
            res.collectif_scheme_accompaniment
              ?.accompaniment_collectif_scheme_compensation_remuneration || 'no',
          accompaniment_collectif_scheme_new_activity_sector:
            res.collectif_scheme_accompaniment
              ?.accompaniment_collectif_scheme_new_activity_sector || 'other',

          accompaniment_collectif_scheme_situations:
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_situations ||
            'reception',
          accompaniment_collectif_scheme_number_rdv:
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_number_rdv || 0,
          accompaniment_collectif_scheme_number_job_offers:
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_number_job_offers ||
            0,
          accompaniment_collectif_scheme_number_workshop:
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_number_workshop || 0,

          accompaniment_collectif_scheme_formation_type:
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_formation_type ||
            'other',
          accompaniment_collectif_scheme_opinion_consultant:
            res.collectif_scheme_accompaniment?.accompaniment_collectif_scheme_opinion_consultant ||
            'no-opinion',
          accompaniment_collectif_scheme_financing_formation:
            res.collectif_scheme_accompaniment
              ?.accompaniment_collectif_scheme_financing_formation || 'none',
          synthesis: res.collectif_scheme_accompaniment?.synthesis || '',
        });

        // OK
        setUserFormationAccompaniment({
          ...res.formation_accompaniment,
          accompaniment_type: 'formation',
          accompaniment_start_date: formatedDate(
            res.formation_accompaniment?.accompaniment_start_date,
          ),
          accompaniment_end_date: formatedDate(res.formation_accompaniment?.accompaniment_end_date),
          accompaniment_first_appointment_date: formatedDate(
            res.formation_accompaniment?.accompaniment_first_appointment_date,
          )
        });

        // OK
        setUserOtherAccompaniment({
          ...res.other_accompaniment,
          accompaniment_type: 'other',
          accompaniment_start_date: formatedDate(res.other_accompaniment?.accompaniment_start_date),
          accompaniment_end_date: formatedDate(res.other_accompaniment?.accompaniment_end_date),
          accompaniment_first_appointment_date: formatedDate(
            res.other_accompaniment?.accompaniment_first_appointment_date,
          )
        });

        setFinancial({
          ...res.financial,
        })
      }
    });
  }, [props.id]);

  const formatedDate = (date) => {
    if (!date || date === '0000-00-00 00:00:00') {
      return '';
    }
    const parsedDate = moment(date, 'YYYY-MM-DD HH:mm:ss');

    if (parsedDate.isValid()) {
      return parsedDate.format('DD/MM/YYYY');
    } else {
      return '';
    }
  };

  const getNewDocument = (name, blob) => {
    const document = { upload_name: name, upload: blob, title: name };
    if (documents.length > 0) setDocuments([...documents, document]);
    else setDocuments([document]);
    setDisabledSaveButton(false);
  };

  const handleDeleteDocument = (id) => {
    const docs = documents.filter((document) => document.id !== id);
    setDocuments(docs);
    setDisabledSaveButton(false);
  };

  

  const getNewFinancialDocument = (name, blob) => {
    const document = { upload_name: name, upload: blob, title: name };
    setFinancial({...financial, upload_name: name, upload: blob});
    setFinancialDocuments([document]);


    setDisabledSaveButton(false);
  };

  const handleDeleteFinancialDocument = (id) => {
    const docs = financialDocuments.filter((document) => document.id !== id);
    setFinancialDocuments(docs);
    setFinancial({...financial, upload_name: null, upload: null, url: null});
    
    setDisabledSaveButton(false);
  };

  const onSave = () => {
    const resetFieldsToNull = (data) => {
      const nullifiedData = {};
      for (const key in data) {
        if (key !== 'id' && key !== 'user_id') {
          nullifiedData[key] = null;
        } else {
          nullifiedData[key] = data[key];
        }
      }
      return nullifiedData;
    };
    switch (accompanimentType) {
      case 'appraisal':
        const dataAppraisal = {
          user_appraisal_accompaniment: {
            ...userAppraisalAccompaniment,
            accompaniment_start_date: moment(
              userAppraisalAccompaniment.accompaniment_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_end_date: moment(
              userAppraisalAccompaniment.accompaniment_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_first_appointment_date: moment(
              userAppraisalAccompaniment.accompaniment_first_appointment_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_date_six_months: moment(
              userAppraisalAccompaniment.accompaniment_date_six_months,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },
          user_VAE_accompaniment: resetFieldsToNull(userVAEAccompaniment),
          user_collective_accompaniment: resetFieldsToNull(userCollectiveAccompaniment),
          outplacement_individual_accompaniment: resetFieldsToNull(
            userOutplacementIndividualAccompaniment,
          ),
          coaching_accompaniment: resetFieldsToNull(userCoachingAccompaniment),
          formation_accompaniment: resetFieldsToNull(userFormationAccompaniment),
          business_creation_accompaniment: resetFieldsToNull(userBusinessCreationAccompaniment),
          collectif_scheme_accompaniment: resetFieldsToNull(userCollectiveSchemeAccompaniment),
          user_orientation_review: resetFieldsToNull(userOrientationReview),
          other_accompaniment: resetFieldsToNull(userOtherAccompaniment),
          selected_accompaniment: selected_accompaniment,
          documents: documents,
          financial
          
        };

        updateUserProfile(props.id, dataAppraisal).then(() => {
          setDisabledSaveButton(true);
        });
        break;

      case 'outplacement_individual':
        const dataOutplacementIndividual = {
          outplacement_individual_accompaniment: {
            ...userOutplacementIndividualAccompaniment,
            accompaniment_start_date: moment(
              userOutplacementIndividualAccompaniment.accompaniment_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_end_date: moment(
              userOutplacementIndividualAccompaniment.accompaniment_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_outplacement_individual_join_date: moment(
              userOutplacementIndividualAccompaniment.accompaniment_outplacement_individual_join_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_outplacement_individual_start_date: moment(
              userOutplacementIndividualAccompaniment.accompaniment_outplacement_individual_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_outplacement_individual_end_date: moment(
              userOutplacementIndividualAccompaniment.accompaniment_outplacement_individual_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_outplacement_individual_start_job_date: moment(
              userOutplacementIndividualAccompaniment.accompaniment_outplacement_individual_start_job_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_outplacement_individual_formation_start_date: moment(
              userOutplacementIndividualAccompaniment.accompaniment_outplacement_individual_formation_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_outplacement_individual_formation_end_date: moment(
              userOutplacementIndividualAccompaniment.accompaniment_outplacement_individual_formation_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_outplacement_individual_real_end_date: moment(
              userOutplacementIndividualAccompaniment.accompaniment_outplacement_individual_real_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },

          user_collective_accompaniment: resetFieldsToNull(userCollectiveAccompaniment),
          coaching_accompaniment: resetFieldsToNull(userCoachingAccompaniment),
          user_appraisal_accompaniment: resetFieldsToNull(userAppraisalAccompaniment),
          user_VAE_accompaniment: resetFieldsToNull(userVAEAccompaniment),
          formation_accompaniment: resetFieldsToNull(userFormationAccompaniment),
          business_creation_accompaniment: resetFieldsToNull(userBusinessCreationAccompaniment),
          collectif_scheme_accompaniment: resetFieldsToNull(userCollectiveSchemeAccompaniment),
          user_orientation_review: resetFieldsToNull(userOrientationReview),
          other_accompaniment: resetFieldsToNull(userOtherAccompaniment),
          selected_accompaniment: selected_accompaniment,
          documents: documents,
          financial
        };

        updateUserProfile(props.id, dataOutplacementIndividual).then(() => {
          setDisabledSaveButton(true);
        });
        break;

      case 'vae':
        const dataVAE = {
          user_VAE_accompaniment: {
            ...userVAEAccompaniment,
            accompaniment_start_date: moment(
              userVAEAccompaniment.accompaniment_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_end_date: moment(
              userVAEAccompaniment.accompaniment_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_eligibility_date: moment(
              userVAEAccompaniment.accompaniment_eligibility_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_first_appointment_date: moment(
              userVAEAccompaniment.accompaniment_first_appointment_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },
          user_appraisal_accompaniment: resetFieldsToNull(userAppraisalAccompaniment),
          user_collective_accompaniment: resetFieldsToNull(userCollectiveAccompaniment),
          outplacement_individual_accompaniment: resetFieldsToNull(
            userOutplacementIndividualAccompaniment,
          ),
          coaching_accompaniment: resetFieldsToNull(userCoachingAccompaniment),
          formation_accompaniment: resetFieldsToNull(userFormationAccompaniment),
          business_creation_accompaniment: resetFieldsToNull(userBusinessCreationAccompaniment),
          collectif_scheme_accompaniment: resetFieldsToNull(userCollectiveSchemeAccompaniment),
          user_orientation_review: resetFieldsToNull(userOrientationReview),
          other_accompaniment: resetFieldsToNull(userOtherAccompaniment),
          selected_accompaniment: accompanimentType,
          documents: documents,
          financial
        };

        updateUserProfile(props.id, dataVAE).then(() => {
          setDisabledSaveButton(true);
        });
        break;

      case 'coaching':
        const dataCoaching = {
          coaching_accompaniment: {
            ...userCoachingAccompaniment,
            accompaniment_start_date: moment(
              userCoachingAccompaniment.accompaniment_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_end_date: moment(
              userCoachingAccompaniment.accompaniment_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_first_appointment_date: moment(
              userCoachingAccompaniment.accompaniment_first_appointment_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },
          user_appraisal_accompaniment: resetFieldsToNull(userAppraisalAccompaniment),
          user_VAE_accompaniment: resetFieldsToNull(userVAEAccompaniment),
          user_collective_accompaniment: resetFieldsToNull(userCollectiveAccompaniment),
          outplacement_individual_accompaniment: resetFieldsToNull(
            userOutplacementIndividualAccompaniment,
          ),
          formation_accompaniment: resetFieldsToNull(userFormationAccompaniment),
          business_creation_accompaniment: resetFieldsToNull(userBusinessCreationAccompaniment),
          collectif_scheme_accompaniment: resetFieldsToNull(userCollectiveSchemeAccompaniment),
          user_orientation_review: resetFieldsToNull(userOrientationReview),
          other_accompaniment: resetFieldsToNull(userOtherAccompaniment),
          selected_accompaniment: selected_accompaniment,
          documents: documents,
          financial
        };

        updateUserProfile(props.id, dataCoaching).then(() => {
          setDisabledSaveButton(true);
        });

        break;

      case 'collective_accompaniment':
        const dataCollectiveAccompaniment = {
          user_collective_accompaniment: {
            ...userCollectiveAccompaniment,
            accompaniment_co_join_date: moment(
              userCollectiveAccompaniment.accompaniment_co_join_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_co_start_date: moment(
              userCollectiveAccompaniment.accompaniment_co_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_co_end_date: moment(
              userCollectiveAccompaniment.accompaniment_co_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_co_start_job_date: moment(
              userCollectiveAccompaniment.accompaniment_co_start_job_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_co_formation_start_date: moment(
              userCollectiveAccompaniment.accompaniment_co_formation_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_co_formation_end_date: moment(
              userCollectiveAccompaniment.accompaniment_co_formation_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_co_real_end_date: moment(
              userCollectiveAccompaniment.accompaniment_co_real_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },
          coaching_accompaniment: resetFieldsToNull(userCoachingAccompaniment),
          user_appraisal_accompaniment: resetFieldsToNull(userAppraisalAccompaniment),
          user_VAE_accompaniment: resetFieldsToNull(userVAEAccompaniment),
          outplacement_individual_accompaniment: resetFieldsToNull(
            userOutplacementIndividualAccompaniment,
          ),
          formation_accompaniment: resetFieldsToNull(userFormationAccompaniment),
          business_creation_accompaniment: resetFieldsToNull(userBusinessCreationAccompaniment),
          collectif_scheme_accompaniment: resetFieldsToNull(userCollectiveSchemeAccompaniment),
          user_orientation_review: resetFieldsToNull(userOrientationReview),
          other_accompaniment: resetFieldsToNull(userOtherAccompaniment),
          selected_accompaniment: selected_accompaniment,
          documents: documents,
          financial
        };

        updateUserProfile(props.id, dataCollectiveAccompaniment).then(() => {
          setDisabledSaveButton(true);
        });
        break;

      case 'orientation_review':
        const dataOrientationReview = {
          user_orientation_review: {
            ...userOrientationReview,
            accompaniment_start_date: moment(
              userOrientationReview.accompaniment_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_end_date: moment(
              userOrientationReview.accompaniment_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_first_appointment_date: moment(
              userOrientationReview.accompaniment_first_appointment_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },

          user_collective_accompaniment: resetFieldsToNull(userCollectiveAccompaniment),
          coaching_accompaniment: resetFieldsToNull(userCoachingAccompaniment),
          user_appraisal_accompaniment: resetFieldsToNull(userAppraisalAccompaniment),
          user_VAE_accompaniment: resetFieldsToNull(userVAEAccompaniment),
          outplacement_individual_accompaniment: resetFieldsToNull(
            userOutplacementIndividualAccompaniment,
          ),
          formation_accompaniment: resetFieldsToNull(userFormationAccompaniment),
          business_creation_accompaniment: resetFieldsToNull(userBusinessCreationAccompaniment),
          collectif_scheme_accompaniment: resetFieldsToNull(userCollectiveSchemeAccompaniment),
          other_accompaniment: resetFieldsToNull(userOtherAccompaniment),
          selected_accompaniment: 'user_orientation_review',
          documents: documents,
          financial
        };

        updateUserProfile(props.id, dataOrientationReview).then((response) => {
          setDisabledSaveButton(true);
        });
        break;

      case 'business_creation':
        const dataBusinessCreation = {
          business_creation_accompaniment: {
            ...userBusinessCreationAccompaniment,
            accompaniment_start_date: moment(
              userBusinessCreationAccompaniment.accompaniment_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_end_date: moment(
              userBusinessCreationAccompaniment.accompaniment_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_first_appointment_date: moment(
              userBusinessCreationAccompaniment.accompaniment_first_appointment_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },
          user_orientation_review: resetFieldsToNull(userOrientationReview),
          user_collective_accompaniment: resetFieldsToNull(userCollectiveAccompaniment),
          coaching_accompaniment: resetFieldsToNull(userCoachingAccompaniment),
          user_appraisal_accompaniment: resetFieldsToNull(userAppraisalAccompaniment),
          user_VAE_accompaniment: resetFieldsToNull(userVAEAccompaniment),
          outplacement_individual_accompaniment: resetFieldsToNull(
            userOutplacementIndividualAccompaniment,
          ),
          formation_accompaniment: resetFieldsToNull(userFormationAccompaniment),
          collectif_scheme_accompaniment: resetFieldsToNull(userCollectiveSchemeAccompaniment),
          other_accompaniment: resetFieldsToNull(userOtherAccompaniment),
          selected_accompaniment: selected_accompaniment,
          documents: documents,
          financial
        };

        updateUserProfile(props.id, dataBusinessCreation).then(() => {
          setDisabledSaveButton(true);
        });
        break;

      case 'collective_scheme':
        const dataCollectiveScheme = {
          collectif_scheme_accompaniment: {
            ...userCollectiveSchemeAccompaniment,
            accompaniment_start_date: moment(
              userCollectiveSchemeAccompaniment.accompaniment_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_end_date: moment(
              userCollectiveSchemeAccompaniment.accompaniment_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_collectif_scheme_join_date: moment(
              userCollectiveSchemeAccompaniment.accompaniment_collectif_scheme_join_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_collectif_scheme_start_date: moment(
              userCollectiveSchemeAccompaniment.accompaniment_collectif_scheme_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_collectif_scheme_end_date: moment(
              userCollectiveSchemeAccompaniment.accompaniment_collectif_scheme_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_collectif_scheme_start_job_date: moment(
              userCollectiveSchemeAccompaniment.accompaniment_collectif_scheme_start_job_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_collectif_scheme_formation_start_date: moment(
              userCollectiveSchemeAccompaniment.accompaniment_collectif_scheme_formation_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_collectif_scheme_formation_end_date: moment(
              userCollectiveSchemeAccompaniment.accompaniment_collectif_scheme_formation_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_collectif_scheme_real_end_date: moment(
              userCollectiveSchemeAccompaniment.accompaniment_collectif_scheme_real_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },
          user_collective_accompaniment: resetFieldsToNull(userCollectiveAccompaniment),
          coaching_accompaniment: resetFieldsToNull(userCoachingAccompaniment),
          user_appraisal_accompaniment: resetFieldsToNull(userAppraisalAccompaniment),
          user_VAE_accompaniment: resetFieldsToNull(userVAEAccompaniment),
          outplacement_individual_accompaniment: resetFieldsToNull(
            userOutplacementIndividualAccompaniment,
          ),
          formation_accompaniment: resetFieldsToNull(userFormationAccompaniment),
          business_creation_accompaniment: resetFieldsToNull(userBusinessCreationAccompaniment),
          user_orientation_review: resetFieldsToNull(userOrientationReview),
          other_accompaniment: resetFieldsToNull(userOtherAccompaniment),
          selected_accompaniment: 'collectif_scheme_accompaniment',
          documents: documents,
          financial
        };

        updateUserProfile(props.id, dataCollectiveScheme).then(() => {
          setDisabledSaveButton(true);
        });
        break;

      case 'formation':
        const dataFormation = {
          formation_accompaniment: {
            ...userFormationAccompaniment,
            accompaniment_start_date: moment(
              userFormationAccompaniment.accompaniment_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_end_date: moment(
              userFormationAccompaniment.accompaniment_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_first_appointment_date: moment(
              userFormationAccompaniment.accompaniment_first_appointment_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },
          business_creation_accompaniment: resetFieldsToNull(userBusinessCreationAccompaniment),
          user_orientation_review: resetFieldsToNull(userOrientationReview),
          user_collective_accompaniment: resetFieldsToNull(userCollectiveAccompaniment),
          coaching_accompaniment: resetFieldsToNull(userCoachingAccompaniment),
          user_appraisal_accompaniment: resetFieldsToNull(userAppraisalAccompaniment),
          user_VAE_accompaniment: resetFieldsToNull(userVAEAccompaniment),
          outplacement_individual_accompaniment: resetFieldsToNull(
            userOutplacementIndividualAccompaniment,
          ),
          collectif_scheme_accompaniment: resetFieldsToNull(userCollectiveSchemeAccompaniment),
          other_accompaniment: resetFieldsToNull(userOtherAccompaniment),
          selected_accompaniment: selected_accompaniment,
          documents: documents,
          financial
        };

        updateUserProfile(props.id, dataFormation).then(() => {
          setDisabledSaveButton(true);
        });
        break;

      case 'other':
        const dataOther = {
          other_accompaniment: {
            ...userOtherAccompaniment,
            accompaniment_start_date: moment(
              userOtherAccompaniment.accompaniment_start_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_end_date: moment(
              userOtherAccompaniment.accompaniment_end_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
            accompaniment_first_appointment_date: moment(
              userOtherAccompaniment.accompaniment_first_appointment_date,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD'),
          },

          formation_accompaniment: resetFieldsToNull(userFormationAccompaniment),
          business_creation_accompaniment: resetFieldsToNull(userBusinessCreationAccompaniment),
          user_orientation_review: resetFieldsToNull(userOrientationReview),
          user_collective_accompaniment: resetFieldsToNull(userCollectiveAccompaniment),
          coaching_accompaniment: resetFieldsToNull(userCoachingAccompaniment),
          user_appraisal_accompaniment: resetFieldsToNull(userAppraisalAccompaniment),
          user_VAE_accompaniment: resetFieldsToNull(userVAEAccompaniment),
          outplacement_individual_accompaniment: resetFieldsToNull(
            userOutplacementIndividualAccompaniment,
          ),
          collectif_scheme_accompaniment: resetFieldsToNull(userCollectiveSchemeAccompaniment),
          selected_accompaniment: selected_accompaniment,
          documents: documents,
          financial
        };

        updateUserProfile(props.id, dataOther).then(() => {
          setDisabledSaveButton(true);
        });
        break;

      default:
        return;
    }
  };

  useEffect(() => {
    props.getBeneficiary(props.id);
    location.state = { canChange: true };
  }, []);

  useEffect(() => {
    const { user } = props;
    setIsTalent(user.role === 'beneficiary' ? true : false);
  }, [props.user]);

  useEffect(() => {
    if (!disabledSaveButton) {
      location.state = { canChange: false };
    } else location.state = { canChange: true };
  }, [disabledSaveButton]);

  const handleChangeAccompaniment = (newValue) => {
    setDisabledSaveButton(false);
    const { id, value } = newValue;
    if (id === 'accompaniment_type') {
      setAccompanimentType(value);
    }

    switch (accompanimentType || value) {
      case 'appraisal':
        setUserAppraisalAccompaniment({ ...userAppraisalAccompaniment, [id]: value });
        break;

      case 'outplacement_individual':
        setOutplacementIndividualAccompaniment({
          ...userOutplacementIndividualAccompaniment,
          [id]: value,
        });
        break;

      case 'vae':
        setUserVAEAccompaniment({ ...userVAEAccompaniment, [id]: value });
        break;

      case 'coaching':
        setUserCoachingAccompaniment({ ...userCoachingAccompaniment, [id]: value });
        break;

      case 'collective_accompaniment':
        setUserCollectiveAccompaniment({ ...userCollectiveAccompaniment, [id]: value });
        break;

      case 'orientation_review':
        setUserOrientationReview({ ...userOrientationReview, [id]: value });
        break;

      case 'collective_scheme':
        setUserCollectifSchemeAccompaniment({ ...userCollectiveSchemeAccompaniment, [id]: value });
        break;

      case 'business_creation':
        setUserBusinessCreationAccompaniment({ ...userBusinessCreationAccompaniment, [id]: value });
        break;

      case 'formation':
        setUserFormationAccompaniment({ ...userFormationAccompaniment, [id]: value });
        break;

      case 'other':
        setUserOtherAccompaniment({ ...userOtherAccompaniment, [id]: value });
        break;

      default:
        return;
    }
  };

  const handleFinancialChange = (newValue) => {
    const { id, value } = newValue;
    setFinancial({ ...financial, [id]: value });
    setDisabledSaveButton(false);
  }

  const renderAccompanimentTalentSide = () => {
    return null;
  };

  const renderAccompanimentConsultantSide = () => {
    return (
      <div className="consultant-beneficiary-accompaniment-content">
        <div className="consultant-beneficiary-accompaniment-type">{renderAccompaniment()}</div>
        {accompanimentType === 'collective_accompaniment' && renderAccompanimentCollective()}
        {accompanimentType === 'outplacement_individual' &&
          renderAccompanimentIndividualOutplacement()}
        {accompanimentType === 'collective_scheme' && renderAccompanimentSchemeCollective()}
      </div>
    );
  };

  const renderAppraisalAccompaniment = () => {
    const {
      accompaniment_appraisal_availability,
      accompaniment_appraisal_type,
      accompaniment_group,
      accompaniment_survey_count,
      accompaniment_start_date,
      accompaniment_end_date,
      accompaniment_home_office,
      accompaniment_synchronous_time,
      accompaniment_asynchronous_time,
      accompaniment_terms,
      accompaniment_survey_type,
      accompaniment_handicap_amendment_requirements,
      accompaniment_date_six_months,
      accompaniment_first_appointment_date,
      accompaniment_appraisal_situation_follow_up,
      accompaniment_appraisal_france_travail_id,
      accompaniment_global_time,
    } = userAppraisalAccompaniment;

    const {
      financer_state,
      financer_state_color,
      financer_type,
      financer_opacif_type,
      financer_support_request_file,
      financer_support_agreement_file,
      financer_name,
      financial_ht,
      financial_tva,
      total_tva,
      total_ttc,
    } = financial;
    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          startDate={{ id: 'accompaniment_start_date', value: accompaniment_start_date }}
          endDate={{ id: 'accompaniment_end_date', value: accompaniment_end_date }}
          dateSixMonths={{
            id: 'accompaniment_date_six_months',
            value: accompaniment_date_six_months,
          }}
          accompanimentSurveyCount={{
            id: 'accompaniment_survey_count',
            value: accompaniment_survey_count,
          }}
          accompanimentSurveyType={{
            id: 'accompaniment_survey_type',
            value: accompaniment_survey_type,
          }}
          bilanType={{
            id: 'accompaniment_appraisal_type',
            value: accompaniment_appraisal_type,
            options: appraisalTypes,
          }}
          bilanFranceTravailId={{
            id: 'accompaniment_appraisal_france_travail_id',
            value: accompaniment_appraisal_france_travail_id,
          }}
          bilanAvailability={{
            id: 'accompaniment_appraisal_availability',
            value: accompaniment_appraisal_availability
              ? accompaniment_appraisal_availability
              : 'within_work',
            options: appraisalAvailabilities,
          }}
          situationFollowUp={{
            id: 'accompaniment_appraisal_situation_follow_up',
            value: accompaniment_appraisal_situation_follow_up
              ? accompaniment_appraisal_situation_follow_up
              : ' ',
            options: situationFollowUpList,
          }}
          homeOffice={{ id: 'accompaniment_home_office', value: accompaniment_home_office }}
          synchronousTime={{
            id: 'accompaniment_synchronous_time',
            value: accompaniment_synchronous_time,
          }}
          asynchronousTime={{
            id: 'accompaniment_asynchronous_time',
            value: accompaniment_asynchronous_time,
          }}
          globalTime={{
            id: 'accompaniment_global_time',
            value: accompaniment_global_time,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: accompaniment_handicap_amendment_requirements,
          }}
          firstAppointmentDate={{
            id: 'accompaniment_first_appointment_date',
            value: accompaniment_first_appointment_date,
          }}
          terms={{ id: 'accompaniment_terms', value: accompaniment_terms, options: termsList }}
          group={{ id: 'accompaniment_group', value: accompaniment_group, options: [] }}
          onChange={handleChangeAccompaniment}
        />
        {/* <FinancialEditionCard
          financerState={{
            id: 'financer_state',
            value: financer_state,
            options: financerStateList,
          }}
          financerStateColor={financer_state_color}
          financerType={{
            id: 'financer_type',
            value: financer_type,
            options: financerTypeList,
          }}
          financerOpacifType={{
            id: 'financer_opacif_type',
            value: financer_opacif_type,
            options: financerOpacifTypeList,
          }}
          financerName={{
            id: 'financer_name',
            value: financer_name,
          }}
          financerSupportRequestFile={{
            id: 'financer_support_request_file',
            value: financer_support_request_file,
          }}
          financerSupportAgreementFile={{ id: 'financer_support_agreement_file', value: financer_support_agreement_file }}
          financialHT={{
            id: 'financial_ht',
            value: financial_ht,
          }}
          financialTVA={{
            id: 'financial_tva',
            value: financial_tva,
            options: financialTVAList,
          }}
          totalTVA={total_tva}
          totalTTC={total_ttc}
          locale={financerTypeLocale}
          onChange={handleFinancialChange}
          financialDocuments={financialDocuments}
          getNewFinancialDocument={getNewFinancialDocument}
          handleDeleteFinancialDocument={handleDeleteFinancialDocument}

        /> */}

        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderOutplacementIndividualAccompaniment = () => {
    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          // HandicapAmendmentRequirement={{
          //   id: 'accompaniment_handicap_amendment_requirements',
          //   value: 'no',
          // }}
          onChange={handleChangeAccompaniment}
        />
        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderVAEAcompaniment = () => {
    const {
      accompaniment_start_date,
      accompaniment_end_date,
      accompaniment_group,
      accompaniment_synchronous_time,
      accompaniment_asynchronous_time,
      accompaniment_home_office,
      accompaniment_eligibility_date,
      accompaniment_booklet_date,
      accompaniment_vae_availability,
      accompaniment_terms,
      accompaniment_handicap_amendment_requirements,
      accompaniment_first_appointment_date,
      accompaniment_appraisal_situation_follow_up,
      accompaniment_global_time,
    } = userVAEAccompaniment;

    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          startDate={{ id: 'accompaniment_start_date', value: accompaniment_start_date }}
          endDate={{ id: 'accompaniment_end_date', value: accompaniment_end_date }}
          group={{ id: 'accompaniment_group', value: accompaniment_group, options: [] }}
          synchronousTime={{
            id: 'accompaniment_synchronous_time',
            value: accompaniment_synchronous_time,
          }}
          asynchronousTime={{
            id: 'accompaniment_asynchronous_time',
            value: accompaniment_asynchronous_time,
          }}
          globalTime={{
            id: 'accompaniment_global_time',
            value: accompaniment_global_time,
          }}
          homeOffice={{ id: 'accompaniment_home_office', value: accompaniment_home_office }}
          terms={{ id: 'accompaniment_terms', value: accompaniment_terms, options: termsList }}
          vaeAvailability={{
            id: 'accompaniment_vae_availability',
            value: accompaniment_vae_availability,
            options: vaeAvailabilities,
          }}
          vaeEligibilityDate={{
            id: 'accompaniment_eligibility_date',
            value: accompaniment_eligibility_date,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: accompaniment_handicap_amendment_requirements,
          }}
          firstAppointmentDate={{
            id: 'accompaniment_first_appointment_date',
            value: accompaniment_first_appointment_date,
          }}
          situationFollowUp={{
            id: 'accompaniment_appraisal_situation_follow_up',
            value: accompaniment_appraisal_situation_follow_up
              ? accompaniment_appraisal_situation_follow_up
              : ' ',
            options: situationFollowUpList,
          }}
          bookletDate={{ id: 'accompaniment_booklet_date', value: accompaniment_booklet_date }}
          onChange={handleChangeAccompaniment}
        />
        {/* <FinancialEditionCard
          financerState={{
            id: 'financer_state',
            value: financer_state,
            options: financerStateList,
          }}
          financerStateColor={financer_state_color}
          financerType={{
            id: 'financer_type',
            value: financer_type,
            options: financerTypeList,
          }}
          financerOpacifType={{
            id: 'financer_opacif_type',
            value: financer_opacif_type,
            options: financerOpacifTypeList,
          }}
          financerName={{
            id: 'financer_name',
            value: financer_name,
          }}
          financerSupportRequestFile={{
            id: 'financer_support_request_file',
            value: financer_support_request_file,
          }}
          financerSupportAgreementFile={{ id: 'financer_support_agreement_file', value: financer_support_agreement_file }}
          financialHT={{
            id: 'financial_ht',
            value: financial_ht,
          }}
          financialTVA={{
            id: 'financial_tva',
            value: financial_tva,
            options: financialTVAList,
          }}
          totalTVA={total_tva}
          totalTTC={total_ttc}
          locale={financerTypeLocale}
          onChange={handleFinancialChange}
          financialDocuments={financialDocuments}
          getNewFinancialDocument={getNewFinancialDocument}
          handleDeleteFinancialDocument={handleDeleteFinancialDocument}

        /> */}
        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderCoachingAccompaniment = () => {
    const {
      accompaniment_start_date,
      accompaniment_end_date,
      accompaniment_handicap_amendment_requirements,
      accompaniment_synchronous_time,
      accompaniment_asynchronous_time,
      accompaniment_first_appointment_date,
      accompaniment_appraisal_situation_follow_up,
      accompaniment_terms,
      accompaniment_global_time,
    } = userCoachingAccompaniment;

    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: accompaniment_handicap_amendment_requirements,
          }}
          synchronousTime={{
            id: 'accompaniment_synchronous_time',
            value: accompaniment_synchronous_time,
          }}
          asynchronousTime={{
            id: 'accompaniment_asynchronous_time',
            value: accompaniment_asynchronous_time,
          }}
          globalTime={{
            id: 'accompaniment_global_time',
            value: accompaniment_global_time,
          }}
          startDate={{ id: 'accompaniment_start_date', value: accompaniment_start_date }}
          endDate={{ id: 'accompaniment_end_date', value: accompaniment_end_date }}
          situationFollowUp={{
            id: 'accompaniment_appraisal_situation_follow_up',
            value: accompaniment_appraisal_situation_follow_up
              ? accompaniment_appraisal_situation_follow_up
              : ' ',
            options: situationFollowUpList,
          }}
          firstAppointmentDate={{
            id: 'accompaniment_first_appointment_date',
            value: accompaniment_first_appointment_date,
          }}
          terms={{ id: 'accompaniment_terms', value: accompaniment_terms, options: termsList }}
          onChange={handleChangeAccompaniment}
        />
        {/* <FinancialEditionCard
          financerState={{
            id: 'financer_state',
            value: financer_state,
            options: financerStateList,
          }}
          financerStateColor={financer_state_color}
          financerType={{
            id: 'financer_type',
            value: financer_type,
            options: financerTypeList,
          }}
          financerOpacifType={{
            id: 'financer_opacif_type',
            value: financer_opacif_type,
            options: financerOpacifTypeList,
          }}
          financerName={{
            id: 'financer_name',
            value: financer_name,
          }}
          financerSupportRequestFile={{
            id: 'financer_support_request_file',
            value: financer_support_request_file,
          }}
          financerSupportAgreementFile={{ id: 'financer_support_agreement_file', value: financer_support_agreement_file }}
          financialHT={{
            id: 'financial_ht',
            value: financial_ht,
          }}
          financialTVA={{
            id: 'financial_tva',
            value: financial_tva,
            options: financialTVAList,
          }}
          totalTVA={total_tva}
          totalTTC={total_ttc}
          locale={financerTypeLocale}
          onChange={handleFinancialChange}
          financialDocuments={financialDocuments}
          getNewFinancialDocument={getNewFinancialDocument}
          handleDeleteFinancialDocument={handleDeleteFinancialDocument}

        /> */}
        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderCollectiveAccompaniment = () => {
    const { accompaniment_collective_type, accompaniment_handicap_amendment_requirements } =
      userCollectiveAccompaniment;

    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          accompanimentCollectiveType={{
            id: 'accompaniment_collective_type',
            value: accompaniment_collective_type,
            options: typeCollectiveAccompaniment,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: accompaniment_handicap_amendment_requirements,
          }}
          onChange={handleChangeAccompaniment}
        />
        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderOrientationReviewAccompaniment = () => {
    const {
      accompaniment_start_date,
      accompaniment_end_date,
      accompaniment_survey_count,
      accompaniment_survey_type,
      accompaniment_handicap_amendment_requirements,
      accompaniment_synchronous_time,
      accompaniment_asynchronous_time,
      accompaniment_first_appointment_date,
      accompaniment_appraisal_situation_follow_up,
      accompaniment_terms,
      accompaniment_global_time,
    } = userOrientationReview;

    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          startDate={{ id: 'accompaniment_start_date', value: accompaniment_start_date }}
          endDate={{ id: 'accompaniment_end_date', value: accompaniment_end_date }}
          accompanimentSurveyCount={{
            id: 'accompaniment_survey_count',
            value: parseInt(accompaniment_survey_count, 10) || 0, // accompaniment_survey_count,
          }}
          accompanimentSurveyType={{
            id: 'accompaniment_survey_type',
            value: accompaniment_survey_type,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: accompaniment_handicap_amendment_requirements,
          }}
          synchronousTime={{
            id: 'accompaniment_synchronous_time',
            value: accompaniment_synchronous_time,
          }}
          asynchronousTime={{
            id: 'accompaniment_asynchronous_time',
            value: accompaniment_asynchronous_time,
          }}
          globalTime={{
            id: 'accompaniment_global_time',
            value: accompaniment_global_time,
          }}
          situationFollowUp={{
            id: 'accompaniment_appraisal_situation_follow_up',
            value: accompaniment_appraisal_situation_follow_up
              ? accompaniment_appraisal_situation_follow_up
              : ' ',
            options: situationFollowUpList,
          }}
          firstAppointmentDate={{
            id: 'accompaniment_first_appointment_date',
            value: accompaniment_first_appointment_date,
          }}
          terms={{ id: 'accompaniment_terms', value: accompaniment_terms, options: termsList }}
          onChange={handleChangeAccompaniment}
        />
        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderBusinessCreationAccompaniment = () => {
    const {
      accompaniment_start_date,
      accompaniment_end_date,
      accompaniment_handicap_amendment_requirements,
      accompaniment_synchronous_time,
      accompaniment_asynchronous_time,
      accompaniment_first_appointment_date,
      accompaniment_terms,
      accompaniment_global_time
    } = userBusinessCreationAccompaniment;

    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          startDate={{ id: 'accompaniment_start_date', value: accompaniment_start_date }}
          endDate={{ id: 'accompaniment_end_date', value: accompaniment_end_date }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: accompaniment_handicap_amendment_requirements,
          }}
          synchronousTime={{
            id: 'accompaniment_synchronous_time',
            value: accompaniment_synchronous_time,
          }}
          asynchronousTime={{
            id: 'accompaniment_asynchronous_time',
            value: accompaniment_asynchronous_time,
          }}
          globalTime={{
            id: 'accompaniment_global_time',
            value: accompaniment_global_time,
          }}
          firstAppointmentDate={{
            id: 'accompaniment_first_appointment_date',
            value: accompaniment_first_appointment_date,
          }}
          terms={{ id: 'accompaniment_terms', value: accompaniment_terms, options: termsList }}
          onChange={handleChangeAccompaniment}
        />
        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderCollectiveSchemeAccompaniment = () => {
    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: 'no',
          }}
          onChange={handleChangeAccompaniment}
        />
        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderFormationAccompaniment = () => {
    const {
      accompaniment_start_date,
      accompaniment_end_date,
      accompaniment_handicap_amendment_requirements,
      accompaniment_synchronous_time,
      accompaniment_asynchronous_time,
      accompaniment_first_appointment_date,
      accompaniment_terms,
      accompaniment_formation,
      accompaniment_global_time,
      accompaniment_formation_title,
    } = userFormationAccompaniment;

    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          startDate={{ id: 'accompaniment_start_date', value: accompaniment_start_date }}
          endDate={{ id: 'accompaniment_end_date', value: accompaniment_end_date }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: accompaniment_handicap_amendment_requirements,
          }}
          synchronousTime={{
            id: 'accompaniment_synchronous_time',
            value: accompaniment_synchronous_time,
          }}
          asynchronousTime={{
            id: 'accompaniment_asynchronous_time',
            value: accompaniment_asynchronous_time,
          }}
          globalTime={{
            id: 'accompaniment_global_time',
            value: accompaniment_global_time,
          }}
          firstAppointmentDate={{
            id: 'accompaniment_first_appointment_date',
            value: accompaniment_first_appointment_date,
          }}
          formation={{
            id: 'accompaniment_formation',
            value: accompaniment_formation ? accompaniment_formation : 'intra',
            options: formationList,
          }}
          formationTitle={{
            id: 'accompaniment_formation_title',
            value: accompaniment_formation_title,
          }}
          terms={{ id: 'accompaniment_terms', value: accompaniment_terms, options: termsList }}
          onChange={handleChangeAccompaniment}
        />
        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderOtherAccompaniment = () => {
    const {
      accompaniment_start_date,
      accompaniment_end_date,
      accompaniment_other_name,
      accompaniment_handicap_amendment_requirements,
      accompaniment_synchronous_time,
      accompaniment_asynchronous_time,
      accompaniment_appraisal_situation_follow_up,
      accompaniment_first_appointment_date,
      accompaniment_terms,
      accompaniment_global_time,
    } = userOtherAccompaniment;

    return (
      <div className="consultant-beneficiary-accompaniment-header">
        <AccompEditionCard
          title={'Accompagnement du talent'}
          locale={AccompanimentTypeLocale}
          accompanimentType={{
            id: 'accompaniment_type',
            value: accompanimentType,
            options: accompanimentTypes,
          }}
          startDate={{ id: 'accompaniment_start_date', value: accompaniment_start_date }}
          endDate={{ id: 'accompaniment_end_date', value: accompaniment_end_date }}
          otherAccompaniment={{ id: 'accompaniment_other_name', value: accompaniment_other_name }}
          HandicapAmendmentRequirement={{
            id: 'accompaniment_handicap_amendment_requirements',
            value: accompaniment_handicap_amendment_requirements,
          }}
          synchronousTime={{
            id: 'accompaniment_synchronous_time',
            value: accompaniment_synchronous_time,
          }}
          asynchronousTime={{
            id: 'accompaniment_asynchronous_time',
            value: accompaniment_asynchronous_time,
          }}
          globalTime={{
            id: 'accompaniment_global_time',
            value: accompaniment_global_time,
          }}
          situationFollowUp={{
            id: 'accompaniment_appraisal_situation_follow_up',
            value: accompaniment_appraisal_situation_follow_up
              ? accompaniment_appraisal_situation_follow_up
              : ' ',
            options: situationFollowUpList,
          }}
          firstAppointmentDate={{
            id: 'accompaniment_first_appointment_date',
            value: accompaniment_first_appointment_date,
          }}
          terms={{ id: 'accompaniment_terms', value: accompaniment_terms, options: termsList }}
          onChange={handleChangeAccompaniment}
        />
        {/* <DocumentAccCard documents={documents}/> */}
      </div>
    );
  };

  const renderAccompaniment = () => {
    return (
      <>
        {accompanimentType === 'appraisal' && renderAppraisalAccompaniment()}
        {accompanimentType === 'outplacement_individual' &&
          renderOutplacementIndividualAccompaniment()}
        {accompanimentType === 'vae' && renderVAEAcompaniment()}
        {accompanimentType === 'coaching' && renderCoachingAccompaniment()}
        {accompanimentType === 'collective_accompaniment' && renderCollectiveAccompaniment()}
        {accompanimentType === 'orientation_review' && renderOrientationReviewAccompaniment()}
        {accompanimentType === 'business_creation' && renderBusinessCreationAccompaniment()}
        {accompanimentType === 'collective_scheme' && renderCollectiveSchemeAccompaniment()}
        {accompanimentType === 'formation' && renderFormationAccompaniment()}
        {accompanimentType === 'other' && renderOtherAccompaniment()}
      </>
    );
  };

  const renderAccompanimentCollective = () => {
    const {
      accompaniment_co_system,
      accompaniment_co_join_date,
      accompaniment_co_duration,
      accompaniment_co_signed_membership_charter,
      accompaniment_co_signed_eeo,
      accompaniment_co_start_date,
      accompaniment_co_end_date,
      accompaniment_co_job_company,
      accompaniment_co_start_job_date,
      accompaniment_co_job_location,
      accompaniment_co_job_seniority,
      accompaniment_co_job_remuneration,
      accompaniment_co_new_job,
      accompaniment_co_new_remuneration,
      accompaniment_co_compensation_remuneration,
      accompaniment_co_new_activity_sector,
      accompaniment_co_solutions,
      accompaniment_co_other_solution,
      accompaniment_co_situations,
      accompaniment_co_number_rdv,
      accompaniment_co_number_job_offers,
      accompaniment_co_number_workshop,
      accompaniment_co_formation_type,
      accompaniment_co_formation_name,
      accompaniment_co_opinion_consultant,
      accompaniment_co_financing_formation,
      accompaniment_co_formation_start_date,
      accompaniment_co_formation_end_date,
      accompaniment_co_cost_formation,
      accompaniment_co_duration_formation,
      synthesis,
      accompaniment_co_real_end_date,
      accompaniment_co_handicap_situation,
      // accompaniment_co_duration_formation
      // accompaniment_co_duration_formation
    } = userCollectiveAccompaniment;

    return (
      <div className="consultant-beneficiary-accompaniment-collective">
        <AccompCoSystemCard
          title={'Dispositif'}
          entrySystem={{ id: 'accompaniment_co_system', value: accompaniment_co_system }}
          joinDate={{ id: 'accompaniment_co_join_date', value: accompaniment_co_join_date }}
          duration={{ id: 'accompaniment_co_duration', value: accompaniment_co_duration }}
          signedMembershipCharter={{
            id: 'accompaniment_co_signed_membership_charter',
            value: accompaniment_co_signed_membership_charter,
            options: optionsYesNo,
          }}
          signedEEO={{
            id: 'accompaniment_co_signed_eeo',
            value: accompaniment_co_signed_eeo,
            options: optionsYesNo,
          }}
          startDate={{ id: 'accompaniment_co_start_date', value: accompaniment_co_start_date }}
          endDate={{ id: 'accompaniment_co_end_date', value: accompaniment_co_end_date }}
          endRealDate={{
            id: 'accompaniment_co_real_end_date',
            value: accompaniment_co_real_end_date,
          }}
          readOnly={false}
          situations={{
            id: 'accompaniment_co_situations',
            value: accompaniment_co_situations,
            options: optionsSitutation,
          }}
          handicapSituation={{
            id: 'accompaniment_co_handicap_situation',
            value: accompaniment_co_handicap_situation,
            options: optionsYesNo,
          }}
          locale={AccompanimentCoSystemLocale}
          onChange={handleChangeAccompaniment}
        />
        <CurrentJobCard
          title={'Poste actuel'}
          company={{ id: 'accompaniment_co_job_company', value: accompaniment_co_job_company }}
          startDate={{
            id: 'accompaniment_co_start_job_date',
            value: accompaniment_co_start_job_date ? '' + accompaniment_co_start_job_date : "",
          }}
          location={{ id: 'accompaniment_co_job_location', value: accompaniment_co_job_location }}
          seniority={{
            id: 'accompaniment_co_job_seniority',
            value: accompaniment_co_job_seniority,
          }}
          remuneration={{
            id: 'accompaniment_co_job_remuneration',
            value: accompaniment_co_job_remuneration,
          }}
          readOnly={false}
          locale={AccompanimentCoJobLocale}
          onChange={handleChangeAccompaniment}
        />
        <SolutionCard
          title={'Solution identifiée'}
          newJob={{ id: 'accompaniment_co_new_job', value: accompaniment_co_new_job }}
          newRemuneration={{
            id: 'accompaniment_co_new_remuneration',
            value: accompaniment_co_new_remuneration,
          }}
          compensationRemuneration={{
            id: 'accompaniment_co_compensation_remuneration',
            value: accompaniment_co_compensation_remuneration,
            options: optionsDifferentialRemuneration,
          }}
          newActivitySector={{
            id: 'accompaniment_co_new_activity_sector',
            value: accompaniment_co_new_activity_sector,
            options: activityOptions,
          }}
          solutions={{
            id: 'accompaniment_co_solutions',
            value: accompaniment_co_solutions,
            options: optionsSolutions,
          }}
          otherSolution={{
            id: 'accompaniment_co_other_solution',
            value: accompaniment_co_other_solution,
          }}
          locale={AccompanimentCoSolutionLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />
        <FollowupProjectCard
          title={'Suivi du projet'}
          numberOfRdv={{
            id: 'accompaniment_co_number_rdv',
            value: accompaniment_co_number_rdv,
            options: optionsNumbers,
          }}
          numberOfJobOffer={{
            id: 'accompaniment_co_number_job_offers',
            value: accompaniment_co_number_job_offers,
            options: optionsNumbers,
          }}
          numberOfWorkshop={{
            id: 'accompaniment_co_number_workshop',
            value: accompaniment_co_number_workshop,
            options: optionsNumbers,
          }}
          locale={AccompanimentCoFollowupProjectLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />
        <FormationCard
          title={'Formation'}
          formationType={{
            id: 'accompaniment_co_formation_type',
            value: accompaniment_co_formation_type,
            options: optionsTypeFormation,
          }}
          nameFormation={{
            id: 'accompaniment_co_formation_name',
            value: accompaniment_co_formation_name,
          }}
          opinionConsultant={{
            id: 'accompaniment_co_opinion_consultant',
            value: accompaniment_co_opinion_consultant,
            options: optionsOpinionConsultant,
          }}
          financingFormation={{
            id: 'accompaniment_co_financing_formation',
            value: accompaniment_co_financing_formation,
            options: optionsFinancing,
          }}
          startDate={{
            id: 'accompaniment_co_formation_start_date',
            value: accompaniment_co_formation_start_date,
          }}
          endDate={{
            id: 'accompaniment_co_formation_end_date',
            value: accompaniment_co_formation_end_date,
          }}
          costFormation={{
            id: 'accompaniment_co_cost_formation',
            value: accompaniment_co_cost_formation,
          }}
          durationFormation={{
            id: 'accompaniment_co_duration_formation',
            value: accompaniment_co_duration_formation,
          }}
          locale={AccompCoFormationLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />

        <SyntheseCoCard
          title={'Synthèse en vue de la commission de suivi'}
          syntheses={synthesis}
          locale={AccompanimentSyntheseCoLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />
        <AccompCoSynthesisCard />

        {/* <AccompClientMissionCard title={"Attribuer une mission"} user={props.user} /> */}
      </div>
    );
  };

  const renderAccompanimentSchemeCollective = () => {
    const {
      accompaniment_collectif_scheme_system,
      accompaniment_collectif_scheme_join_date,
      accompaniment_collectif_scheme_duration,
      accompaniment_collectif_scheme_signed_membership_charter,
      accompaniment_collectif_scheme_signed_eeo,
      accompaniment_collectif_scheme_start_date,
      accompaniment_collectif_scheme_end_date,
      accompaniment_collectif_scheme_job_company,
      accompaniment_collectif_scheme_start_job_date,
      accompaniment_collectif_scheme_job_location,
      accompaniment_collectif_scheme_job_seniority,
      accompaniment_collectif_scheme_job_remuneration,
      accompaniment_collectif_scheme_new_job,
      accompaniment_collectif_scheme_new_remuneration,
      accompaniment_collectif_scheme_compensation_remuneration,
      accompaniment_collectif_scheme_new_activity_sector,
      accompaniment_collectif_scheme_solutions,
      accompaniment_collectif_scheme_other_solution,
      accompaniment_collectif_scheme_situations,
      accompaniment_collectif_scheme_number_rdv,
      accompaniment_collectif_scheme_number_job_offers,
      accompaniment_collectif_scheme_number_workshop,
      accompaniment_collectif_scheme_formation_type,
      accompaniment_collectif_scheme_formation_name,
      accompaniment_collectif_scheme_opinion_consultant,
      accompaniment_collectif_scheme_financing_formation,
      accompaniment_collectif_scheme_formation_start_date,
      accompaniment_collectif_scheme_formation_end_date,
      accompaniment_collectif_scheme_cost_formation,
      accompaniment_collectif_scheme_duration_formation,
      synthesis,
      accompaniment_collectif_scheme_real_end_date,
      accompaniment_collectif_scheme_handicap_situation,
    } = userCollectiveSchemeAccompaniment;

    return (
      <div className="consultant-beneficiary-accompaniment-collective">
        <AccompCoSystemCard
          title={'Dispositif'}
          entrySystem={{
            id: 'accompaniment_collectif_scheme_system',
            value: accompaniment_collectif_scheme_system,
          }}
          joinDate={{
            id: 'accompaniment_collectif_scheme_join_date',
            value: accompaniment_collectif_scheme_join_date,
          }}
          duration={{
            id: 'accompaniment_collectif_scheme_duration',
            value: accompaniment_collectif_scheme_duration,
          }}
          signedMembershipCharter={{
            id: 'accompaniment_collectif_scheme_signed_membership_charter',
            value: accompaniment_collectif_scheme_signed_membership_charter,
            options: optionsYesNo,
          }}
          signedEEO={{
            id: 'accompaniment_collectif_scheme_signed_eeo',
            value: accompaniment_collectif_scheme_signed_eeo,
            options: optionsYesNo,
          }}
          startDate={{
            id: 'accompaniment_collectif_scheme_start_date',
            value: accompaniment_collectif_scheme_start_date,
          }}
          endRealDate={{
            id: 'accompaniment_collectif_scheme_real_end_date',
            value: accompaniment_collectif_scheme_real_end_date,
          }}
          endDate={{
            id: 'accompaniment_collectif_scheme_end_date',
            value: accompaniment_collectif_scheme_end_date,
          }}
          situations={{
            id: 'accompaniment_collectif_scheme_situations',
            value: accompaniment_collectif_scheme_situations,
            options: optionsSitutation,
          }}
          handicapSituation={{
            id: 'accompaniment_collectif_scheme_handicap_situation',
            value: accompaniment_collectif_scheme_handicap_situation,
            options: optionsYesNo,
          }}
          readOnly={false}
          locale={AccompanimentCoSystemLocale}
          onChange={handleChangeAccompaniment}
        />

        <CurrentJobCard
          title={'Poste actuel'}
          company={{
            id: 'accompaniment_collectif_scheme_job_company',
            value: accompaniment_collectif_scheme_job_company,
          }}
          startDate={{
            id: 'accompaniment_collectif_scheme_start_job_date',
            value: accompaniment_collectif_scheme_start_job_date,
          }}
          location={{
            id: 'accompaniment_collectif_scheme_job_location',
            value: accompaniment_collectif_scheme_job_location,
          }}
          seniority={{
            id: 'accompaniment_collectif_scheme_job_seniority',
            value: accompaniment_collectif_scheme_job_seniority,
          }}
          remuneration={{
            id: 'accompaniment_collectif_scheme_job_remuneration',
            value: accompaniment_collectif_scheme_job_remuneration,
          }}
          readOnly={false}
          locale={AccompanimentCoJobLocale}
          onChange={handleChangeAccompaniment}
        />

        <SolutionCard
          title={'Solution identifiée'}
          newJob={{
            id: 'accompaniment_collectif_scheme_new_job',
            value: accompaniment_collectif_scheme_new_job,
          }}
          newRemuneration={{
            id: 'accompaniment_collectif_scheme_new_remuneration',
            value: accompaniment_collectif_scheme_new_remuneration,
          }}
          compensationRemuneration={{
            id: 'accompaniment_collectif_scheme_compensation_remuneration',
            value: accompaniment_collectif_scheme_compensation_remuneration,
            options: optionsDifferentialRemuneration,
          }}
          newActivitySector={{
            id: 'accompaniment_collectif_scheme_new_activity_sector',
            value: accompaniment_collectif_scheme_new_activity_sector,
            options: activityOptions,
          }}
          solutions={{
            id: 'accompaniment_collectif_scheme_solutions',
            value: accompaniment_collectif_scheme_solutions,
            options: optionsSolutions,
          }}
          otherSolution={{
            id: 'accompaniment_collectif_scheme_other_solution',
            value: accompaniment_collectif_scheme_other_solution,
          }}
          locale={AccompanimentCoSolutionLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />

        <FollowupProjectCard
          title={'Suivi du projet'}
          numberOfRdv={{
            id: 'accompaniment_collectif_scheme_number_rdv',
            value: accompaniment_collectif_scheme_number_rdv,
            options: optionsNumbers,
          }}
          numberOfJobOffer={{
            id: 'accompaniment_collectif_scheme_number_job_offers',
            value: accompaniment_collectif_scheme_number_job_offers,
            options: optionsNumbers,
          }}
          numberOfWorkshop={{
            id: 'accompaniment_collectif_scheme_number_workshop',
            value: accompaniment_collectif_scheme_number_workshop,
            options: optionsNumbers,
          }}
          locale={AccompanimentCoFollowupProjectLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />

        <FormationCard
          title={'Formation'}
          formationType={{
            id: 'accompaniment_collectif_scheme_formation_type',
            value: accompaniment_collectif_scheme_formation_type,
            options: optionsTypeFormation,
          }}
          nameFormation={{
            id: 'accompaniment_collectif_scheme_formation_name',
            value: accompaniment_collectif_scheme_formation_name,
          }}
          opinionConsultant={{
            id: 'accompaniment_collectif_scheme_opinion_consultant',
            value: accompaniment_collectif_scheme_opinion_consultant,
            options: optionsOpinionConsultant,
          }}
          financingFormation={{
            id: 'accompaniment_collectif_scheme_financing_formation',
            value: accompaniment_collectif_scheme_financing_formation,
            options: optionsFinancing,
          }}
          startDate={{
            id: 'accompaniment_collectif_scheme_formation_start_date',
            value: accompaniment_collectif_scheme_formation_start_date,
          }}
          endDate={{
            id: 'accompaniment_collectif_scheme_formation_end_date',
            value: accompaniment_collectif_scheme_formation_end_date,
          }}
          costFormation={{
            id: 'accompaniment_collectif_scheme_cost_formation',
            value: accompaniment_collectif_scheme_cost_formation,
          }}
          durationFormation={{
            id: 'accompaniment_collectif_scheme_duration_formation',
            value: accompaniment_collectif_scheme_duration_formation,
          }}
          locale={AccompCoFormationLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />

        {/* <AccompClientMissionCard title={"Attribuer une mission"} user={props.user} /> */}

        <SyntheseCoCard
          title={'Synthèse en vue de la commission de suivi'}
          syntheses={synthesis}
          locale={AccompanimentSyntheseCoLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />
      </div>
    );
  };

  const renderAccompanimentIndividualOutplacement = () => {
    const {
      accompaniment_outplacement_individual_system,
      accompaniment_outplacement_individual_join_date,
      accompaniment_outplacement_individual_duration,
      accompaniment_outplacement_individual_signed_membership_charter,
      accompaniment_outplacement_individual_signed_eeo,
      accompaniment_outplacement_individual_start_date,
      accompaniment_outplacement_individual_end_date,
      accompaniment_outplacement_individual_job_company,
      accompaniment_outplacement_individual_start_job_date,
      accompaniment_outplacement_individual_job_location,
      accompaniment_outplacement_individual_job_seniority,
      accompaniment_outplacement_individual_job_remuneration,
      accompaniment_outplacement_individual_new_job,
      accompaniment_outplacement_individual_new_remuneration,
      accompaniment_outplacement_individual_compensation_remuneration,
      accompaniment_outplacement_individual_new_activity_sector,
      accompaniment_outplacement_individual_solutions,
      accompaniment_outplacement_individual_other_solution,
      accompaniment_outplacement_individual_situations,
      accompaniment_outplacement_individual_number_rdv,
      accompaniment_outplacement_individual_number_job_offers,
      accompaniment_outplacement_individual_number_workshop,
      accompaniment_outplacement_individual_formation_type,
      accompaniment_outplacement_individual_formation_name,
      accompaniment_outplacement_individual_opinion_consultant,
      accompaniment_outplacement_individual_financing_formation,
      accompaniment_outplacement_individual_formation_start_date,
      accompaniment_outplacement_individual_formation_end_date,
      accompaniment_outplacement_individual_cost_formation,
      // accompaniment_outplacement_individual_cost_duration
      accompaniment_outplacement_individual_duration_formation,
      accompaniment_outplacement_individual_real_end_date,
      accompaniment_outplacement_individual_handicap_situation,
      synthesis,
    } = userOutplacementIndividualAccompaniment;

    return (
      <div className="consultant-beneficiary-accompaniment-collective">
        <AccompCoSystemCard
          title={'Dispositif'}
          entrySystem={{
            id: 'accompaniment_outplacement_individual_system',
            value: accompaniment_outplacement_individual_system,
          }}
          joinDate={{
            id: 'accompaniment_outplacement_individual_join_date',
            value: accompaniment_outplacement_individual_join_date,
          }}
          duration={{
            id: 'accompaniment_outplacement_individual_duration',
            value: accompaniment_outplacement_individual_duration,
          }}
          signedMembershipCharter={{
            id: 'accompaniment_outplacement_individual_signed_membership_charter',
            value: accompaniment_outplacement_individual_signed_membership_charter,
            options: optionsYesNo,
          }}
          signedEEO={{
            id: 'accompaniment_outplacement_individual_signed_eeo',
            value: accompaniment_outplacement_individual_signed_eeo,
            options: optionsYesNo,
          }}
          startDate={{
            id: 'accompaniment_outplacement_individual_start_date',
            value: accompaniment_outplacement_individual_start_date,
          }}
          endDate={{
            id: 'accompaniment_outplacement_individual_end_date',
            value: accompaniment_outplacement_individual_end_date,
          }}
          endRealDate={{
            id: 'accompaniment_outplacement_individual_real_end_date',
            value: accompaniment_outplacement_individual_real_end_date,
          }}
          handicapSituation={{
            id: 'accompaniment_outplacement_individual_handicap_situation',
            value: accompaniment_outplacement_individual_handicap_situation,
            options: optionsYesNo,
          }}
          situations={{
            id: 'accompaniment_outplacement_individual_situations',
            value: accompaniment_outplacement_individual_situations,
            options: optionsSitutation,
          }}
          readOnly={false}
          locale={AccompanimentCoSystemLocale}
          onChange={handleChangeAccompaniment}
        />
        <CurrentJobCard
          title={'Poste actuel'}
          company={{
            id: 'accompaniment_outplacement_individual_job_company',
            value: accompaniment_outplacement_individual_job_company,
          }}
          startDate={{
            id: 'accompaniment_outplacement_individual_start_job_date',
            value: accompaniment_outplacement_individual_start_job_date,
          }}
          location={{
            id: 'accompaniment_outplacement_individual_job_location',
            value: accompaniment_outplacement_individual_job_location,
          }}
          seniority={{
            id: 'accompaniment_outplacement_individual_job_seniority',
            value: accompaniment_outplacement_individual_job_seniority,
          }}
          remuneration={{
            id: 'accompaniment_outplacement_individual_job_remuneration',
            value: accompaniment_outplacement_individual_job_remuneration,
          }}
          readOnly={false}
          locale={AccompanimentCoJobLocale}
          onChange={handleChangeAccompaniment}
        />

        <SolutionCard
          title={'Solution identifiée'}
          newJob={{
            id: 'accompaniment_outplacement_individual_new_job',
            value: accompaniment_outplacement_individual_new_job,
          }}
          newRemuneration={{
            id: 'accompaniment_outplacement_individual_new_remuneration',
            value: accompaniment_outplacement_individual_new_remuneration,
          }}
          compensationRemuneration={{
            id: 'accompaniment_outplacement_individual_compensation_remuneration',
            value: accompaniment_outplacement_individual_compensation_remuneration,
            options: optionsDifferentialRemuneration,
          }}
          newActivitySector={{
            id: 'accompaniment_outplacement_individual_new_activity_sector',
            value: accompaniment_outplacement_individual_new_activity_sector,
            options: activityOptions,
          }}
          solutions={{
            id: 'accompaniment_outplacement_individual_solutions',
            value: accompaniment_outplacement_individual_solutions,
            options: optionsSolutions,
          }}
          otherSolution={{
            id: 'accompaniment_outplacement_individual_other_solution',
            value: accompaniment_outplacement_individual_other_solution,
          }}
          locale={AccompanimentCoSolutionLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />

        <FollowupProjectCard
          title={'Suivi du projet'}
          numberOfRdv={{
            id: 'accompaniment_outplacement_individual_number_rdv',
            value: accompaniment_outplacement_individual_number_rdv,
            options: optionsNumbers,
          }}
          numberOfJobOffer={{
            id: 'accompaniment_outplacement_individual_number_job_offers',
            value: accompaniment_outplacement_individual_number_job_offers,
            options: optionsNumbers,
          }}
          numberOfWorkshop={{
            id: 'accompaniment_outplacement_individual_number_workshop',
            value: accompaniment_outplacement_individual_number_workshop,
            options: optionsNumbers,
          }}
          locale={AccompanimentCoFollowupProjectLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />

        <FormationCard
          title={'Formation'}
          formationType={{
            id: 'accompaniment_outplacement_individual_formation_type',
            value: accompaniment_outplacement_individual_formation_type,
            options: optionsTypeFormation,
          }}
          nameFormation={{
            id: 'accompaniment_outplacement_individual_formation_name',
            value: accompaniment_outplacement_individual_formation_name,
          }}
          opinionConsultant={{
            id: 'accompaniment_outplacement_individual_opinion_consultant',
            value: accompaniment_outplacement_individual_opinion_consultant,
            options: optionsOpinionConsultant,
          }}
          financingFormation={{
            id: 'accompaniment_outplacement_individual_financing_formation',
            value: accompaniment_outplacement_individual_financing_formation,
            options: optionsFinancing,
          }}
          startDate={{
            id: 'accompaniment_outplacement_individual_formation_start_date',
            value: accompaniment_outplacement_individual_formation_start_date,
          }}
          endDate={{
            id: 'accompaniment_outplacement_individual_formation_end_date',
            value: accompaniment_outplacement_individual_formation_end_date,
          }}
          costFormation={{
            id: 'accompaniment_outplacement_individual_cost_formation',
            value: accompaniment_outplacement_individual_cost_formation,
          }}
          durationFormation={{
            id: 'accompaniment_outplacement_individual_duration_formation',
            value: accompaniment_outplacement_individual_duration_formation,
          }}
          locale={AccompCoFormationLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />
        <SyntheseCoCard
          title={'Synthèse en vue de la commission de suivi'}
          syntheses={synthesis}
          locale={AccompanimentSyntheseCoLocale}
          readOnly={false}
          onChange={handleChangeAccompaniment}
        />
      </div>
    );
  };

  const renderAside = () => {
    return <ConsultantBeneficiaryAside />;
  };

  const { beneficiary } = props;

  if (beneficiary === null) {
    return null;
  }

  return (
    <ConsultantBeneficiary renderAside={renderAside} onSave={() => null}>
      <div className="consultant-beneficiary-accompaniment-page">
        <PrimaryButton
          id="edit-beneficiary-profile-content"
          label={'Sauvegarder'}
          onClick={onSave}
          disabled={disabledSaveButton}
        />
        
        {isTalent ? renderAccompanimentTalentSide() : renderAccompanimentConsultantSide()}
        <FinancialEditionCard
          financerState={{
            id: 'financer_state',
            value: financial.financer_state,
            options: financerStateList,
          }}
          financerStateColor={financial.financer_state_color}
          financerType={{
            id: 'financer_type',
            value: financial.financer_type,
            options: financerTypeList,
          }}
          financerOpacifType={{
            id: 'financer_opacif_type',
            value: financial.financer_opacif_type,
            options: financerOpacifTypeList,
          }}
          financerName={{
            id: 'financer_name',
            value: financial.financer_name,
          }}
          financerSupportRequestFile={{
            id: 'financer_support_request_file',
            value: financial.financer_support_request_file,
          }}
          financerSupportAgreementFile={{ id: 'financer_support_agreement_file', value: financial.financer_support_agreement_file }}
          financialHT={{
            id: 'financial_ht',
            value: financial.financial_ht,
          }}
          financialTVA={{
            id: 'financial_tva',
            value: financial.financial_tva,
            options: financialTVAList,
          }}
          totalTVA={financial.financial_tva_amount}
          totalTTC={financial.financial_ttc}
          locale={financerTypeLocale}
          onChange={handleFinancialChange}
          financialDocuments={financialDocuments}
          getNewFinancialDocument={getNewFinancialDocument}
          handleDeleteFinancialDocument={handleDeleteFinancialDocument}

        />
        
        <DocumentAccCard
          documents={documents}
          newDocument={getNewDocument}
          deleteDocument={handleDeleteDocument}
        />
      </div>
    </ConsultantBeneficiary>
  );
}

// eslint-disable-next-line no-func-assign
ConsultantBeneficiaryAccompanimentPage = reduxForm({
  form: 'testt',
  asyncChangeFields: ['title', 'date', 'rdv_at', 'rdv_to'],
  asyncValidate: (values) =>
    asyncValidate(values, {
      title: { rule: 'required' },
      date: { rule: 'required|dateWithFormat:DD/MM/YYYY|dateAfterNow:DD/MM/YYYY' },
      rdv_at: { rule: 'required' },
      rdv_to: {
        rule: `required|above:${values.rdv_at}`,
        message: 'Erreur',
      },
    }),
})(ConsultantBeneficiaryAccompanimentPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Page(ConsultantBeneficiaryAccompanimentPage));
