import React, {useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton'
import CabinetCard from '../../components/organisms/CabinetCard/CabinetCard'
import CentralTestCard from '../../components/organisms/CentralTestCard/CentralTestCard'
import LineMenu from '../../components/organisms/LineMenu/LineMenu'
import SubscribeCard from '../../components/organisms/SubscribeCard/SubscribeCard'
import FileUpload from '../../containers/global/FileUpload/FileUpload'
import Page from '../../containers/global/Page/Page'

import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside'
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout'
import TabbedContentLayout from '../../layouts/TabbedContentLayout/TabbedContentLayout'
import { history } from '../../navigation/History'
import { actions as OfficeActions, selectors as CabinetSelectors } from '../../redux/OfficeRedux'

import france_regions from "../../static/json/regions.json"
import './ManagerOfficePage.scss';
import { WhiteBrandCard } from '../../components/organisms/WhiteBrandCard/WhiteBrandCard'

const mapStateToProps = (state) => ({
  office: CabinetSelectors.office(state),
})

const mapDispatchToProps = (dispatch) => ({
  getOffice: () => dispatch(OfficeActions.getCurrentUserOffice()),
  setOffice: (office) => dispatch(OfficeActions.setOffice(office)),
  saveOffice: (office) => dispatch(OfficeActions.saveCurrentUserOffice(office)),
  contactMyTalents: () => dispatch(OfficeActions.contactMyTalents()),
})

const ManagerOfficePage = (props) => {
  const [office, setOffice] = useState(null);
  const [officeChange, setOfficeChange] = useState(false);
  const [, setUnblock] = useState(null)

  // const getDerivedStateFromProps = ({ office }, state) => {
  //   if (office) {
  //     return null
  //   }

  //   return office ? { office } : null
  // }

  const handleOfficeChange = ({ id, value, valid }) => {
    if (!id) {
      return
    }
    setOffice({ ...office, [id]: value });
    setOfficeChange(true);
  }

  const handleFileChange = ({ file }) => {
    handleOfficeChange({ id: 'image_url', value: file.url })
  }

  const handleSave = () => {
    setOfficeChange(false);
    props.saveOffice(office)
  }

  const handleContactClick = () => {
    props.contactMyTalents();
  }

  useEffect(() => {
    const unblock = history.block(() => {
      if (officeChange) {
        return 'true'
      }
    })

    setUnblock(unblock);
    props.getOffice();
  }, [officeChange])

  useEffect(() => {
    if (props.office) {
      setOffice(props.office)
    }
  }, [props])

  // manque le return 

  const renderTabs = () => {
    const links = [{ id: 'office', label: "Mon cabinet" }]

    return <LineMenu value={links[0].id} links={links} />
  }

  const renderContent = () => {

    if (!office) {
      return
    }

    else {
      const { email, name, phone, image_url, region, emails } = office;
      const hasCentralTestRequiredAttributes = email && name && phone;
      return (
        <div className='manager-office-page-content'>
          <PrimaryButton id="save" label={"Sauvegarder"} onClick={handleSave} />
          <FileUpload id="image_url" accept="image/*" onChange={handleFileChange}>
            {({ onClick, uploading }) => (
              <CabinetCard
                logoUrl={`${process.env.REACT_APP_ASSETS_API_STATIC_URL}${(image_url).replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')}`}
                email={email}
                emails={emails}
                name={name}
                phone={phone}
                region={region}
                uploading={uploading}
                onChange={handleOfficeChange}
                onFileClick={onClick}
                locale={{
                  title: "Présentation",
                  emailTitle: "Email",
                  emailPlaceholder: "Email...",
                  emailsTitle: "Adresses email de contact",
                  emailsPlaceholder: "mail1@exemple.com, mail2@exemple.com...",
                  phoneTitle: "Téléphone",
                  phonePlaceholder: "Téléphone...",
                  nameTitle: "Nom de l'entreprise",
                  namePlaceholder: "Nom de l'entreprise...",
                  regionTitle: "Localisation",
                  regionPlaceholder: "Votre localisation",
                  regions: Object.values(france_regions),
                }}
              />
            )}
          </FileUpload>

          <SubscribeCard
            labelTitle={"Abonnement"}
            labelCount={"Total accès consultant disponibles"}
            labelButton={"Contacter My Talents"}
            count={office.no_consultants}
            onButtonClick={handleContactClick}
          />

          <CentralTestCard canRegister={hasCentralTestRequiredAttributes} />

          <WhiteBrandCard office_id={office.id}/>
        </div>
      )
    }

  }

  const aside = <ManagerPageAside />
  const content = (
    <TabbedContentLayout header={renderTabs()} content={renderContent()} />
  )
  return (
    <div className="manager-office-page">
      <LeftAsideLayout aside={aside} content={content} />
    </div>
  )

}

export default connect(mapStateToProps, mapDispatchToProps)(Page(ManagerOfficePage))