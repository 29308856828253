import React, { useEffect, useState } from 'react';

import { NewTag } from '../../atoms/NewTag/NewTag';
import Icon from '../../atoms/Icon/Icon';
import { DeleteMemberGroupPopin } from '../../../containers/groups/DeleteMemberGroupPopin/DeleteMemberGroupPopin';
import { getBeneficiary } from '../../../api/BeneficiaryAPI';

import './MemberGroupCard.scss';

export const MemberGroupCard = ({ member, deleteMember, onClick }) => {
  const [showModalDeleteMember, setShowModalDeleteMember] = useState(false);
  const [beneficiary, setBeneficiary] = useState({});

  const handleDeleteMember = () => {
    deleteMember(member);
    setShowModalDeleteMember(false);
  };
  const renderAvatar = (image) => {
    if (image) return <img className="avatar" src={image} alt="avatar-member" />;
    return (
      <div className="avatar">
        <Icon icon="person" color={'accent'} />
      </div>
    );
  };

  useEffect(() => {
    getBeneficiary(member.id).then((response) => {
      setBeneficiary(response);
      console.log('response', response);
    });
  }, [member]);

  const statuses = [
    { key: '', value: 'Tous' },
    { key: 'created', value: 'Prospect' },
    { key: 'wait_financer', value: 'En attente de financement' },
    { key: 'in_validation', value: 'En attente de validation' },
    { key: 'in_progress', value: 'En cours' },
    { key: 'follow_up', value: 'Suivi professionel' },
    { key: 'archived', value: 'Historique' },
  ];
  const options = statuses.map((item) => ({
    value: item.key,
    label: item.value,
  }));

  const { first_name, last_name, email, phone, image_url } = member;
  console.log('member', member);
  return (
    <div className="member-group-card" onClick={onClick}>
      <div className="member-group-card-header">
        <NewTag title={statuses.find((e) => e.key === beneficiary?.status ?? '')?.value ?? ''} />
        <div onClick={() => setShowModalDeleteMember(true)}>
          <Icon icon={'trash2'} />
        </div>
      </div>
      <div className="member-group-card-content">
        <div className="member-informations">
          {renderAvatar(
            `${process.env.REACT_APP_ASSETS_API_STATIC_URL}${
              image_url
                ? image_url.replace(process.env.REACT_APP_OLD_UPLOADS_API_STATIC_URL, '')
                : ''
            }`,
          )}
          <h1>
            {first_name} {last_name}
          </h1>
        </div>
        <p>{email}</p>
        <p>{phone}</p>
      </div>
      {/* {showModalDeleteMember && <DeleteMemberGroupPopin member={member} open={showModalDeleteMember} onClose={() => setShowModalDeleteMember(false)} onDeleteMemberGroup={() => {deleteMember(member); setShowModalDeleteMember(false)}}/>} */}
      {showModalDeleteMember && (
        <DeleteMemberGroupPopin
          member={member}
          open={showModalDeleteMember}
          onClose={() => setShowModalDeleteMember(false)}
          onDeleteMemberGroup={handleDeleteMember}
        />
      )}
    </div>
  );
};
