/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Page from '../../containers/global/Page/Page';
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside';
import { getModules, getCourseById } from '../../api/CourseApi';
import { actions as NavigationActions } from '../../redux/NavigationRedux';
import { connect } from 'react-redux';
import { IconBack } from '../../components/atoms/IconBack/IconBack';
import { ButtonsGroup } from '../../components/molecules/ButtonsGroup/ButtonsGroup';
import { QuizButtonsGroup } from '../../components/molecules/QuizButtonsGroup/QuizButtonsGroup';
import { ParcoursQuiz } from '../../components/organisms/ParcoursQuiz/ParcoursQuiz';
import { ParcoursDocument } from '../../components/organisms/ParcoursDocument/ParcoursDocument';
import { ParcoursAppointment } from '../../components/organisms/ParcoursAppointment/ParcoursAppointment';
import { ParcoursSynthesis } from '../../components/organisms/ParcoursSynthesis/ParcoursSynthesis';
import { ParcoursDocumentReturn } from '../../components/organisms/ParcoursDocumentReturn/ParcoursDocumentReturn';
import { ParcoursTimelineSteps } from '../../components/molecules/ParcoursTImelineSteps/ParcoursTimelineSteps';
import { Tag } from '../../components/atoms/Tag/Tag';
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside';
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside';
import { useHistory } from 'react-router-dom';
import './BeneficiaryCourseViewRessourcePage.scss';
import { updateModule, updateModuleContainer } from '../../api/CourseApi';
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { Popin } from '../../containers/global/Popin/Popin';
import AlertPopin from '../../components/organisms/AlertPopin/AlertPopin';
import Icon from '../../components/atoms/Icon/Icon';
import { getAppointmentById } from '../../api/AppointmentsApi';
import { BrowserRouter as Router, Route, Switch, useParams, useLocation } from 'react-router-dom';

const mapStateToProps = (state) => ({
  user: UserSelectors.user(state),
});

const mapDispatchToProps = (dispatch) => ({
  redirect: (pathname) => dispatch(NavigationActions.push(pathname)),
});

const BeneficiaryCourseViewRessourcePage = (props) => {
  const [course, setCourse] = useState([]);
  const [steps, setSteps] = useState([]);
  const [step, setStep] = useState();
  const [moduleContainer, setModuleContainer] = useState([]);
  const [modules, setModules] = useState([]);
  const [module, setModule] = useState();
  const [newModule, setNewModule] = useState();
  const [index, setIndex] = useState();
  const [maxLength, setMaxLength] = useState();
  const [nextModule, setNextModule] = useState();
  const [disabledPreviousModule, setDisabledPreviousModule] = useState(false);
  const [disabledNextQuestion, setDisabledNextQuestion] = useState(true);
  const [disabledPreviousQuestion, setDisabledPreviousQuestion] = useState(true);
  const [indexStepActive, setIndexStepActive] = useState();
  const [indexModuleContainer, setIndexModuleContainer] = useState();
  const [maxLengthModuleContainer, setMaxLengthModuleContainer] = useState();
  const [totalQuestions, setTotalQuestions] = useState();
  const [indexQuestion, setIndexQuestion] = useState(null);
  const [question, setQuestion] = useState();
  const [stateDoneModule, setStateDoneModule] = useState();
  const [userRole, setUserRole] = useState();
  const [isFinishModuleContainer, setIsFinishModuleContainer] = useState(false);
  const [isDoneModuleContainer, setIsDoneModuleContainer] = useState(false);
  const [state, setState] = useState(false);
  const history = useHistory();
  const [stateModuleContainer, setStateModuleContainer] = useState();

  useEffect(() => {
  }, [isDoneModuleContainer]);

  useEffect(() => {
    if (moduleContainer && module && course) {
      if (
        module?.type &&
        (module?.type === 'document' ||
          module.type === 'synthesis' ||
          module.type === 'feedback' ||
          module.type === 'video')
      ) {
        setNextModule(false);
        setDoneDocument(module);
        const s = checkLockekModule();
        setState(s);
        setDisabledNextQuestion(false);
        if (index < maxLength) {
          setNextModule(s);
        }
      } else if (module?.type === 'appointmentEvo') {
        if (module.data[0].appointment_id) {
          getAppointmentById(module.data[0].appointment_id).then((response) => {
            if (response.appointment && response.appointment.rdv_to) {
              if (compareDate(response.appointment.rdv_to)) {
                setNextModule(false);
              } else {
                setNextModule(true);
              }
            }
          });
        }
      } else {
        const s = checkLockekModule();
        setState(s);
        setDisabledNextQuestion(false);
        if (index < maxLength) {
          setNextModule(s);
        }
      }
    }
  }, [moduleContainer, module]);

  const checkLockekModule = () => {
    const stateModuleContainer = checkContainerLocked();
    const index = moduleContainer.modules.findIndex((obj) => obj.id === module.id) + 1;
    const slicedArray = moduleContainer.modules.slice(0, index);
    if (slicedArray.length === 0 && !stateModuleContainer) {
      return true;
    }
    if (!stateModuleContainer) return true;
    const response = slicedArray.some(
      (obj) =>
        (obj.locked === 1 && (obj.done === 1 || obj.done === 0)
          ? 0
          : obj.done === 0
          ? 1
          : obj.done) === 0 && obj.done !== 3,
    );
    return response;
  };

  const checkContainerLocked = () => {
    if (course && course.steps && step) {
      const stepT = course.steps.find((stepTmp) => stepTmp.id === step.id);
      const modules_containers = stepT.modules_containers;
      const indexModuleContainer = modules_containers.findIndex(
        (obj) => obj.id === moduleContainer.id,
      );
      const sliceModuleContainerArray = modules_containers.slice(0, indexModuleContainer);
      const responseContainer = sliceModuleContainerArray.some(
        (obj) =>
          (obj.locked === 1 && obj.done !== 3 ? 0 : obj.done === 0 ? 1 : obj.done) === 0 &&
          obj.done !== 3,
      );
      setStateModuleContainer(responseContainer === true ? 0 : moduleContainer.done);
      return responseContainer === true ? 0 : moduleContainer.done;
    }
    return moduleContainer.done;
  };

  const getUserRole = () => {
    setUserRole(props.user.role);
  };

  const fetchModules = async () => {
    const modules = await getModules(props.match.params.modules_id);
    setModuleContainer(modules.modules_container);
    setModules(modules.modules_container.modules);
    setMaxLength(modules.modules_container.modules.length - 1);
    const indexActiveModule = modules.modules_container.modules.findIndex(
      (element) => element.id === parseInt(props.match.params.module_id),
    );
    setIndex(indexActiveModule);
    if (modules.modules_container.modules[indexActiveModule]) {
      setModule(modules.modules_container.modules[indexActiveModule]);
    }
  };

  const fetchCourse = async () => {
    const course = await getCourseById(props.match.params.course_id);
    setCourse(course.course);
  };

  useEffect(() => {
    fetchModules();
    fetchCourse();
    getUserRole();
  }, []);

  useEffect(() => {
    setSteps(course.steps);
    if (course && moduleContainer && course.steps) {
      const indexStepActive = course.steps.findIndex((step) => step.id === moduleContainer.step_id);
      setIndexStepActive(indexStepActive);
      setStep(course.steps[indexStepActive]);

      const indexModuleContainerActive = course.steps[
        indexStepActive
      ]?.modules_containers.findIndex((mContainer) => mContainer.id === moduleContainer.id);

      indexStepActive
        ? setMaxLengthModuleContainer(course.steps[indexStepActive]?.modules_containers.length)
        : setMaxLengthModuleContainer(course.steps[0]?.modules_containers.length);
      setIndexModuleContainer(indexModuleContainerActive);
    }
  }, [course, moduleContainer]);

  useEffect(() => {
    if (modules[index]) {
      setModule(modules[index]);
    }

    if (index === 0) {
      setDisabledPreviousModule(true);
    } else setDisabledPreviousModule(false);
  }, [index]);

  const compareDate = (date) => {
    const providedDate = new Date(date);
    const currentDate = new Date();
    if (providedDate < currentDate) {
      return true;
    } else {
      return false;
    }
  };

  const getNextModule = async () => {
    if (
      module.type === 'document' ||
      module.type === 'synthesis' ||
      module.type === 'feedback' ||
      module.type === 'video'
    ) {
      setDoneDocument(module);
      setStateDoneModule(3);
      module.data[0].done = 3;
      setModule({ ...module, data: [{ ...module.data[0], done: 3 }] });
      await updateModule(module.data[0]);
    }

    if (module.type === 'appointmentEvo') {
      setDoneDocument(module);
      setStateDoneModule(3);
      module.data[0].done = 3;
      setModule({ ...module, data: [{ ...module.data[0], done: 3 }] });
      await updateModule(module.data[0]);
    }

    index < maxLength ? setIndex(index + 1) : setIndex(maxLength);

    if (index === maxLength) {
      checkDoneForm();
      checkDoneModuleContainer(moduleContainer);
      if (moduleContainer.locked) {
        if (
          !isDoneModuleContainer &&
          !moduleContainer.modules.every((module) => module.done === 3)
        ) {
          document.getElementById('error-module-not-finish').style.display = 'block';
        } else {
          setIsDoneModuleContainer(true);
          document.getElementById('error-module-not-finish').style.display = 'none';
          finishModuleContainer();
        }
      }
      isDoneModuleContainer && finishModuleContainer();
      const modules = await getModules(props.match.params.modules_id);
      // setModuleContainer(modules.modules_container);
      // setModules(modules.modules_container.modules);
      if (!moduleContainer.locked && !isDoneModuleContainer) {
        history.push({
          pathname: `/beneficiary/courses/${props.match.params.course_id}`,
          state: { step: step, quiz: newModule },
        });
      }
    }
  };

  const getPreviousModule = () => {
    if (index > 0) {
      if (indexQuestion === null) {
        setIndex(index - 1);
      } else getPreviousQuestion();
    } else setIndex(0);
  };

  const getNextQuestion = () => {
    setIndexQuestion(indexQuestion + 1);
    // Last question
    if (indexQuestion === totalQuestions || totalQuestions <= 0) {
      setIndex(index + 1);
      setIndexQuestion(null);
    }

    if (
      (indexQuestion === totalQuestions && index === maxLength) ||
      (index === maxLength && totalQuestions <= 0)
    ) {
      checkDoneModuleContainer(moduleContainer);
      setIndex(maxLength);
      setIndexQuestion(totalQuestions);

      isDoneModuleContainer && finishModuleContainer();
      // finishModuleContainer();
    }

    setDisabledPreviousQuestion(false);

    if (!question) {
      if (index === maxLength) {
        if (moduleContainer.locked) {
          isDoneModuleContainer && finishModuleContainer();
        } else {
          finishModuleContainer();
        }
      } else {
        setIndex(index + 1);
        setIndexQuestion(null);
      }
    }
    checkIfAnswer();
    updateModule(module);
  };

  const getPreviousQuestion = () => {
    setIndexQuestion(indexQuestion - 1);

    if (indexQuestion <= 0 || indexQuestion === undefined) {
      setIndex(index - 1);
      setIndexQuestion(null);
    }
  };

  useEffect(() => {
    if (indexQuestion === 0) {
      if (index === 0) {
        setDisabledPreviousQuestion(true);
      } else {
        setDisabledPreviousQuestion(false);
      }
    } else {
      setDisabledPreviousQuestion(false);
    }
  }, [index, indexQuestion]);

  useEffect(() => {
    checkIfAnswer();
  }, [question, state]);

  // // index question
  useEffect(() => {
    if (module && module.type === 'form') {
      const questions = module.data[0].module_form_questions;
      setQuestion(questions[indexQuestion]);

      if (index > 0 && indexQuestion > 0) setDisabledPreviousQuestion(false);
    }
  }, [module, indexQuestion]);

  useEffect(() => {
    if (module && module.type === 'form') {
      const questions = module.data[0].module_form_questions;
      setTotalQuestions(questions.length - 1);
      if (indexQuestion === null) {
        setIndexQuestion(0);
      } else {
        setIndexQuestion(indexQuestion + 1);
      }
    }
  }, [module]);

  const getIndexQuestionActif = (index) => {
    setIndexQuestion(index);
    setQuestion(module.data[0].module_form_questions[index]);
  };

  const getIndexQuestionNotDone = () => {
    const questions = module.data[0].module_form_questions;
    const indexQuestionNotDone = questions?.findIndex(
      (question) =>
        question.answer === undefined || question.answer === null || question.answer === '',
    );

    if (indexQuestionNotDone === -1) {
      if (indexQuestion) setIndexQuestion(indexQuestion);
      else setIndexQuestion(0);
    } else {
      setIndexQuestion(indexQuestionNotDone);
    }
  };

  useEffect(() => {
    module && getIndexQuestionNotDone();
  }, [module]);

  const checkDoneForm = () => {
    if (module && module.type === 'form' && question) {
      const questions = module.data[0].module_form_questions;

      const currentQuestion = questions.find((element) => element.id === question.id);

      if (currentQuestion) currentQuestion.done = 3;

      const isInProgress = questions.some((element) => element.done === 3 || element.done === 2);
      const isAllDone = !questions.some((element) => element.done !== 3);

      if (isAllDone) {
        setStateDoneModule(3);
        const updatedData = { ...module, done: 3 };
        updateModule(updatedData);

        const moduleContainerUpdated = { ...moduleContainer, done: 3 };
        moduleContainerUpdated.modules.forEach((module) => {
          module.id === updatedData.id && Object.assign(module, updatedData);
        });

        setModuleContainer(moduleContainerUpdated);
        checkDoneModuleContainer(moduleContainerUpdated);
        updateModuleContainer(moduleContainerUpdated).then((response) => {
          updatingStep(response.modules_container);
        });
      } else if (isInProgress) {
        setStateDoneModule(2);
        const updatedData = { ...module, done: 2 };
        updateModule(updatedData);
        const moduleContainerUpdated = { ...moduleContainer, done: 2 };
        moduleContainerUpdated.modules.forEach((module) => {
          module.id === updatedData.id && Object.assign(module, updatedData);
        });

        setModuleContainer(moduleContainerUpdated);
        updateModuleContainer(moduleContainerUpdated).then((response) => {
          updatingStep(response.modules_container);
        });
      } else {
        setStateDoneModule(1);
      }
      updateModule(module);
    }
  };

  const updatingStep = (newModuleContainer) => {
    const stepUpdated = { ...step };
    const oldModuleContainerIndex = stepUpdated.modules_containers.findIndex(
      (element) => element.id === moduleContainer.id,
    );

    if (oldModuleContainerIndex !== -1) {
      const oldModuleContainer = { ...stepUpdated.modules_containers[oldModuleContainerIndex] };

      Object.assign(oldModuleContainer, newModuleContainer);

      stepUpdated.modules_containers[oldModuleContainerIndex] = newModuleContainer;
      setStep(stepUpdated);
    }
  };

  useEffect(() => {
    // if (isDoneModuleContainer) getNextModule();
  }, [isDoneModuleContainer]);

  const checkDoneModuleContainer = (moduleC) => {
    const isModuleContainerDone = moduleC.modules.every((module) => module.done === 3);
    setIsDoneModuleContainer(isModuleContainerDone);
  };

  useEffect(() => {
    if (module) setStateDoneModule(module.done);
  }, [module]);

  const checkIfAnswer = (answer) => {
    if (answer && (answer.value === undefined || answer.value?.length === 0)) {
      if (module.locked) {
        setDisabledNextQuestion(true);
      } else {
        if (!state) {
          setDisabledNextQuestion(false);
        } else {
          setDisabledNextQuestion(true);
        }
      }

      if (module.type === 'document_to_return') {
        module.data[0].url_return = answer;
      }
    } else {
      if (!state && module) {
        setDisabledNextQuestion(false);
        checkDoneForm();
        let tmpModule = module;
        if (tmpModule) tmpModule.data[0].done = 3;
        if (tmpModule) {
          setModule(tmpModule);
        }
      } else {
        setDisabledNextQuestion(true);
      }
    }
  };

  const getNewModule = (new_module) => {
    setNewModule(new_module);
    checkDoneForm();
  };

  const setDoneDocument = async (document) => {
    setStateDoneModule(3);
    document.done = 3;
    await updateModule(document);
  };

  const finishModuleContainer = () => {
    updateModule(module);
    updateModuleContainer(moduleContainer);
    setNewModule(module);
    setIsFinishModuleContainer(true);
  };

  const renderFinishModuleContainer = () => {
    return (
      <Popin onClose={() => setIsFinishModuleContainer(false)} open={isFinishModuleContainer}>
        <AlertPopin
          label={'Module terminé'}
          text={'Bravo, vous avez fini votre module !'}
          labelCancelButton={'Annuler'}
          labelConfirmButton={'Continuer mon parcours'}
          onCancelButtonClick={() => setIsFinishModuleContainer(false)}
          onConfirmButtonClick={() =>
            history.push({
              pathname: `/beneficiary/courses/${props.match.params.course_id}`,
              state: { step: step, quiz: newModule },
            })
          }
        />
      </Popin>
    );
  };

  const renderAlertBanner = () => (
    <div className="course-edit-alert">
      <div className="info">
        <strong>Attention : mode édition activé</strong>
        <span className="tooltip-text">
          <Icon icon="help" color="red" /> 
          <span className="tooltip-content">
            Dans le mode édition, lorsque vous parcourez une ressource ou complétez une information, le module ou la ressource se valide automatiquement.
          </span>
        </span>
      </div>
    </div>
  );

  const renderContent = () => {
    if (module) {
      return (
        <div className="beneficiary-course-view">
          {props.user.role === 'consultant' && renderAlertBanner()}
          <div className="beneficiary-course-view-header">
          
            <IconBack
              isText={true}
              iconColor={'grey1'}
              data={{
                pathname: `/beneficiary/courses/${props.match.params.course_id}`,
                state: { step: step, quiz: newModule },
              }}
            />
            {module?.type !== 'form' ? (
              <ButtonsGroup
                onClickPrevious={getPreviousModule}
                onClickNext={getNextModule}
                disabledNext={nextModule}
                disabledPrevious={disabledPreviousModule}
              />
            ) : (
              <QuizButtonsGroup
                onClickPreviousQuestion={getPreviousQuestion}
                onClickNextQuestion={getNextQuestion}
                disabledNextQuestion={disabledNextQuestion}
                disabledPreviousQuestion={disabledPreviousQuestion}
              />
            )}
          </div>

          <div className="beneficiary-course-view-ressouce">
            <p id="error-module-not-finish" style={{ color: 'red', display: 'none' }}>
              Le module n'est pas completé à 100%
            </p>
            {module?.type === 'document' && (
              <>
                <div className="d-flex ms-3">
                  <Tag state={stateDoneModule} />
                  {module.locked ? (
                    <div className="ms-3 d-flex" style={{ position: 'relative' }}>
                      <Icon icon={Icon.icon.Lock} color={Icon.color.Grey1} />
                      <p className="message-lock">
                        * Vous devez compléter le document avant de passer au module suivant
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <ParcoursDocument document={module.data[0]} />
              </>
            )}
            {module?.type === 'form' && (
              <>
                <div className="d-flex ms-3">
                  <Tag state={stateDoneModule} />
                  {module.locked ? (
                    <div className="ms-3 d-flex" style={{ position: 'relative' }}>
                      <Icon icon={Icon.icon.Lock} color={Icon.color.Grey1} />
                      <p className="message-lock">
                        * Vous devez compléter le document avant de passer au module suivant
                      </p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="title-container">
                  <h1>{module.title}</h1>

                  <span>
                    Questionnaire de{' '}
                    {totalQuestions > 0
                      ? totalQuestions + 1 + ' questions'
                      : totalQuestions + 1 + ' question'}{' '}
                  </span>
                  {module.description && <p>{module.description}</p>}
                </div>

                <ParcoursTimelineSteps
                  questions={newModule && newModule.data && newModule.data[0].module_form_questions}
                  sendIndexActif={getIndexQuestionActif}
                  questionActive={question}
                  roleUser={userRole}
                  id={newModule && newModule.id}
                />

                <ParcoursQuiz
                  module={module}
                  question={question}
                  isAnswer={checkIfAnswer}
                  setNewModule={getNewModule}
                />
              </>
            )}
            {module?.type === 'appointmentEvo' && (
              <ParcoursAppointment appointmentId={module.data[0].appointment_id} />
            )}
            {(module?.type === 'synthesis' || module?.type === 'feedback') && (
              <ParcoursSynthesis synthesis={module} />
            )}
            {module?.type === 'document_to_return' && (
              <ParcoursDocumentReturn
                documentToReturn={module}
                isDocument={() => {
                  setNextModule(false);
                  module.done = 3;
                  setModule({ ...module, done: 3 });
                  updateModule(module);
                }}
                setNewModule={getNewModule}
              />
            )}
          </div>
          {isFinishModuleContainer && renderFinishModuleContainer()}
        </div>
      );
    }
  };

  let aside;
  switch (userRole) {
    case 'consultant':
      aside = <ConsultantPageAside />;
      break;
    case 'beneficiary':
      aside = <BeneficiaryPageAside />;
      break;
    case 'manager':
      aside = <ManagerPageAside />;
      break;
    default:
      return null;
  }

  const content = renderContent();

  return (
    <div>
      <LeftAsideLayout aside={aside} content={content} />
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Page(BeneficiaryCourseViewRessourcePage));
