import { axiosInstance } from "./baseApi";

export const createCompagny = async (data) => {
    const response = await axiosInstance.post('centraltest/company/create', data);
    return response.data;
}

export const assignCompagny = async (data) => {
    const response = await axiosInstance.post('centraltest/company/assign', data);
    return response.data;
}

export const getOfficeInfos = async (office_id) => {
    const response = await axiosInstance.get(`centraltest/office_infos/${office_id}`);
    return response.data;
}

export const putTestNumberUpdate = async (data) => {
    const response = await axiosInstance.put('centraltest/tests_number/update', data);
    return response.data;
}

export const getCompagnyTests = async (office_id) => {
    const response = await axiosInstance.get(`centraltest/company/${office_id}/tests`);
    return response.data;
}

export const putAssignTest = async (data) => {
    const response = await axiosInstance.post('centraltest/test/assign', data);
    return response.data;
}

export const getTalentsTests = async (user_id) => {
    const response = await axiosInstance.get(`centraltest/talents/${user_id}/tests`);
    return response.data;
}

export const getTalentMap = async (user_id) => {
    const response = await axiosInstance.get(`centraltest/talents/${user_id}/talent_map`);
    return response.data;
}

export const postResendTest = async (data) => {
    const response = await axiosInstance.post('/centraltest/talents/test/resend', data);
    return response.data;
}

export const updateTest = async (data) => {
    const response = await axiosInstance.put('/centraltest/test/update', data);
    return response.data;
}