/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Page from '../../containers/global/Page/Page';
import BeneficiaryPageAside from '../../containers/beneficiary/BeneficiaryPageAside/BeneficiaryPageAside';
import ConsultantPageAside from '../../containers/consultant/ConsultantPageAside/ConsultantPageAside';
import ManagerPageAside from '../../containers/manager/ManagerPageAside/ManagerPageAside';
import ConsultantBeneficiaryExplorerContainer from '../../containers/consultant/ConsultantBeneficiaryExplorerContainer/ConsultantBeneficiaryExplorerContainer';
import LeftAsideLayout from '../../layouts/LeftAsideLayout/LeftAsideLayout';
import { selectors as UserSelectors } from '../../redux/UserRedux';
import { selectors as OfficeSelector } from '../../redux/OfficeRedux';

import { getBeneficiary } from '../../api/BeneficiaryAPI';
// import { forEach } from 'lodash';
import LoadingSpinner from '../../components/atoms/LoadingSpinner/LoadingSpinner';
import icon from '../../components/atoms/Icon/Icon';
import Icon from '../../components/atoms/Icon/Icon';
import { useSelector } from 'react-redux';
import DataTable from '../../components/molecules/DataTable/DataTable';
import { getCompagnyTests, putAssignTest, getTalentsTests, getTalentMap, postResendTest } from '../../api/CentralTestApi';
import PrimaryButton from '../../components/atoms/PrimaryButton/PrimaryButton';
import { useToaster } from '../../hooks/useToaster';

const mapStateToProps = (state, props) => ({
  user: UserSelectors.user(state),
  office: OfficeSelector.office(state),
});

const columns = [
    'Nom du test',
    'Statut du test',
    'Débuté le',
    'Terminé le',
    'Lien du test',
    'Rapport talent',
    // 'Actions',
  ];

const BeneficiaryExplorationPage = (props) => {
  const [beneficiary, setBeneficiary] = useState({});
  const [office, setOffice] = useState(null);
  const u = useSelector(UserSelectors.user);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [tests, setTests] = useState([]);
  const [individualTests, setIndividualTests] = useState([]);

  const getData = async () => {
    if (!office && tests.length > 0) return;
    if (office?.id) {
      setLoading(true);
      await getCompagnyTests(office.id).then((data) => {
        setTests(data);
      });
      await getTalentsTests(props.user.id).then((data) => {
        setIndividualTests(data);
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [office]);

  useEffect(() => {
    if (props.office) {
      setOffice(props.office);
    }
  }, [props.office]);

  const renderContent = () => {
    return (
      <div className="beneficiary-explorer-container">
        {loading && <LoadingSpinner />}
        <div className="consultant-beneficiary-explorer-table">
          <h2 style={{fontWeight: "bold", display: 'flex', gap: "16px"}}><Icon icon={icon.icon.CentralTest} />Central Test</h2>
          <p>Retrouvez l'ensemble des tests qui vous à été attribué</p>
          <div className="tests-list-wrapper">
            <DataTable columns={columns} data={individualTests} />
          </div>
        </div>
      </div>
    );
  };

  let aside;
  switch (props.user.role) {
    case 'consultant':
      aside = <ConsultantPageAside />;
      break;
    case 'beneficiary':
      aside = <BeneficiaryPageAside />;
      break;
    case 'manager':
      aside = <ManagerPageAside />;
      break;
    default:
      return null;
  }

  const content = renderContent();

  return (
    <div className="beneficiary-course-page" style={{height: "100%"}}>
        <LeftAsideLayout aside={aside} content={content} />
    </div>
  );
};

export default connect(mapStateToProps, null)(Page(BeneficiaryExplorationPage));
