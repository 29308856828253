import React, { useEffect } from 'react';
import { useState } from 'react';
import { Popin } from '../../../../containers/global/Popin/Popin';
import LoadingSpinner from '../../../../components/atoms/LoadingSpinner/LoadingSpinner';
import './AssignTestModal.scss';
import PrimaryButton from '../../../../components/atoms/PrimaryButton/PrimaryButton';
import Icon from '../../../../components/atoms/Icon/Icon';
import { transform } from 'lodash';

const AssignTestModal = ({ isOpen, onClose, onAssign, beneficiary, tests = [] }) => {
  const modalStyles = {
    // minWidth: "670px",
  };
  const [loading, setLoading] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedLang, setSelectedLang] = useState(null);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [disabledActionButton, setDisabledActionButton] = useState(true);

  useEffect(() => {
    setSelectedLang(null);
    setSelectedDetail(null);
    setSelectedLevel(null);
    setDisabledActionButton(true);

  }, [selectedTest]);

  useEffect(() => {
    setSelectedDetail(null);
    setSelectedLevel(null);
    if ((selectedTest?.category ?? null) === 'IT') {
        setDisabledActionButton(true);
    }

  }, [selectedLang]);

  useEffect(() => {
    setSelectedLevel(null);
    setDisabledActionButton(true);
  }, [selectedDetail]);

  return (
    <Popin
      onClose={onClose}
      open={isOpen}
      focusTrapped={false}
      overrideModalStyles={modalStyles}
      classes={'responsive-modal'}
    >
      <div>
        {loading && <LoadingSpinner />}

        <div className="modal-header">
          <h1>Assigner un Test</h1>
        </div>
        <div className="modal-content list">
          {tests.map((test, index) => (
            <div key={index} className="row">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
                onClick={() => {
                  (selectedTest?.id ?? -1) === test.id
                    ? setSelectedTest(null)
                    : setSelectedTest(test);
                }}
              >
                <p>
                  {test.label} - {test.category}
                </p>{' '}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'center',
                  }}
                >
                  <p>
                    {/* <b>{test.price_in_credits}</b>cts */}
                  </p>
                  <div style={{ transform: 'rotate(-90deg)' }}>
                    <Icon icon={Icon.icon.ChevronLeft} />
                  </div>{' '}
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  height: (selectedTest?.id ?? -1) === test.id ? 'auto' : 0,
                }}
                className='list'
              >
                {test &&
                  Object.values(test.languages).map((lang, indexLang) => (
                    <div key={indexLang} className="row" style={{ width: '100%' }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                        onClick={() => {
                          (selectedLang?.id ?? -1) === lang.id
                            ? setSelectedLang(null)
                            : setSelectedLang(lang);
                        }}
                      >
                        {lang?.name}
                        {lang?.details ? (
                          <div style={{ transform: 'rotate(-90deg)' }}>
                            <Icon icon={Icon.icon.ChevronLeft} />
                          </div>
                        ) : (
                          <PrimaryButton
                            label={'Choisir'}
                            onClick={() => {
                                setDisabledActionButton(false);
                                setSelectedLang(lang);
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          width: '100%',
                          overflow: 'hidden',
                          height: (selectedLang?.id ?? -1) === lang.id ? 'auto' : 0,
                        }}
                        className='list'
                      >
                        {lang &&
                          lang?.details &&
                          Object.values(lang?.details).map((detail, indexDetails) => (
                            <div key={indexDetails} className="row">
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                }}
                                onClick={() => {
                                  (selectedDetail?.id ?? -1) === detail.id
                                    ? setSelectedDetail(null)
                                    : setSelectedDetail(detail);
                                }}
                              >
                                {detail?.name}
                                <div style={{ transform: 'rotate(-90deg)' }}>
                                  <Icon icon={Icon.icon.ChevronLeft} />
                                </div>{' '}
                              </div>
                              <div
                                style={{
                                  width: '100%',
                                  overflow: 'hidden',
                                  height: (selectedDetail?.id ?? -1) === detail.id ? 'auto' : 0,
                                }}
                                className='list'
                              >
                                {detail &&
                                  detail?.levels.map((level, indexLevel) => {
                                    const [key, value] = Object.entries(level)[0];
                                    return (
                                      <div key={indexLevel} className="row">
                                        <p>
                                          {key} : {value}
                                        </p>
                                        <PrimaryButton
                                          label={'Choisir'}
                                          onClick={() => {
                                            setDisabledActionButton(false);
                                            setSelectedLevel({ key: key, value: value });
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", padding: "20px 10px" }}>
          {selectedTest ? (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <p>Test : {selectedTest?.label ?? ''}</p>
              <p>Langue : {selectedLang?.name ?? ''}</p>{' '}
              <p>{selectedDetail?.name ? <>Détail: {selectedDetail?.name}</> : ''}</p>{' '}
              <p>
                {selectedLevel ? (
                  <p>
                    {selectedLevel?.key ? <>Level: {selectedLevel?.key}</> : ''}
                  </p>
                ) : (
                  ''
                )}
              </p>
            </div>
          ) : (
            <p>Sélectionner un test</p>
          )}
          {/* <p>couts : {selectedTest?.price_in_credits ?? ""}</p> */}
          <PrimaryButton label={'Assigner'} onClick={() => onAssign({test: selectedTest ?? null, lang: selectedLang ?? null, detail: selectedDetail ?? null, level: selectedLevel ?? null})} disabled={disabledActionButton} />
        </div>
      </div>
    </Popin>
  );
};

export default AssignTestModal;
